<!--  -->
<template>
  <van-dialog v-if="show" v-model="show" class="full-screen" :closeOnPopstate="false">
    <van-nav-bar title="填写发货信息" left-text="返回" left-arrow @click-left="close()" />
    <div class="page">
      <div id="content" class="content">
        <ul id="ul">
          <li>
            <div class="product-information">
              <div class="title">
                商品信息
                <div class="order-time total">{{ orderInfo.CreateTime }} 拍下</div>
              </div>

              <div class="list-data">
                <van-image :src="orderInfo.ProductImage" />
                <div class="state">
                  <div class="van-multi-ellipsis--l2 title">
                    {{ orderInfo.ProductName }}
                  </div>
                  <div class="price">
                    <span> {{ orderInfo.ProductPriceDesc }} </span>
                    <div>x{{ orderInfo.ProductNum }}</div>
                    <div>{{ orderInfo.ProductAmountDesc }}</div>
                  </div>
                </div>
              </div>
              <div class="total">
                <span>共</span>
                {{ orderInfo.ProductNum }}
                <span>件</span>
                &nbsp;
                <span>合计:</span>
                {{ orderInfo.SellerAmountDesc }}
                <span> (含服务费:</span>{{ orderInfo.SellerServiceFeeDesc }}<span>)</span>
              </div>
            </div>

            <div class="item-wrapper shipping-info">
              <div class="title">{{ orderInfo.ProductWarehouseType == 2 ? "取货信息" : "发货信息" }}</div>
              <div class="shipping-address">
                <div class="label">
                  <div class="address-detail" v-if="orderInfo.ProductWarehouseType == 1">
                    <span>收件人姓名：</span>
                    <span>{{ orderInfo.Contact }}</span>
                  </div>
                  <!-- <div class="address-detail">
                  <span> 收件人电话：</span><span>{{ Phone }}</span>
                                                                                                                                                                                                                                                                                                                                                                  </div> -->
                  <div class="address-detail">
                    <span>{{ "地址1：" }}</span><span>{{ orderInfo.Address1 }}</span>
                  </div>
                  <div class="address-detail">
                    <span> {{ "地址2：" }}</span><span>{{ orderInfo.Address2 }}</span>
                  </div>
                  <div class="address-detail">
                    <span> 邮编：</span><span>{{ orderInfo.ZipCode }}</span>
                  </div>
                  <div class="address-detail">
                    <span> 城市：</span><span>{{ orderInfo.City }}</span>
                  </div>
                  <div class="address-detail">
                    <span> 省份：</span><span>{{ orderInfo.Province }}</span>
                  </div>
                  <div class="address-detail">
                    <span> 国家：</span><span>{{ orderInfo.CountryDesc }}</span>
                  </div>
                </div>
              </div>
              <div class="list-btn">
                <van-button round type="info" @click="copyText()">复制{{ orderInfo.ProductWarehouseType == 2 ? "取货信息" : "发货信息" }}信息</van-button>
              </div>
            </div>
            <div id="uploader" class="uploader">
              <div class="title">上传发货凭证</div>
              <van-uploader v-model="imageList" multiple :after-read="afterRead" :before-delete="deleteImage" :max-count="5" image-fit="contain" />
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div id="button">
      <div style="margin: 16rem">
        <van-button round block type="danger" color="#009dff" native-type="submit" @click="submit">确认发货</van-button>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";
import AddressList from "./AddressList.vue";

export default {
  name: "ShipOrder",
  components: {},
  props: ["forward", "back", "refresh"],

  data() {
    return {
      show: false,

      value: 1,
      imageList: [],
      orderInfo: {
        UserId: "",

        ProductImage: "",
        ProductName: "",
        ProductPriceDesc: "",
        ProductNum: "",
        SellerAmountDesc: "",
        SellerServiceFeeDesc: "",
        ProductAmountDesc: "",
        CreateTime: "",
        PayTime: "",
        Id: "",
      },
      ProductNum: "",
      address: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(id) {
      this.load(id);
      this.show = true;
      this.forward?.();
    },

    async load(id) {
      let params = {
        id: id,
      };

      let data = await Service.getOrderDetail(params);
      this.orderInfo = data;
      this.imageList = data.ImageList.filter((v) => v.Type == 2).map((v) => {
        return { Id: v.Id, url: v.Image };
      });
    },

    close() {
      this.back?.();
    },
    copyText() {
      let contact = "";
      if (this.orderInfo.ProductWarehouseType == 1) {
        contact = `收件人姓名：${this.orderInfo.Contact}\n`;
      }
      let address = `${contact}收货地址1：${this.orderInfo.Address1}\n收货地址2：${this.orderInfo.Address2}\n邮编：${this.orderInfo.ZipCode}\n城市：${this.orderInfo.City}\n省份：${this.orderInfo.Province}\n国家：${this.orderInfo.CountryDesc}`;
      Util.copyText(address);
      Toast("复制成功");
    },
    afterRead(file) {
      let files = [];
      if (Array.isArray(file)) {
        files = file;
      } else {
        files.push(file);
      }
      files.forEach(async (item) => {
        try {
          item.status = "uploading";
          let data = {
            FileName: item.file.name,
            File: item.file,
          };
          let result = await Service.uploadImage(data);

          if (this.imageList.some((v) => v.url == result.Url)) {
            let i = this.imageList.indexOf(item);
            if (i > -1) {
              this.imageList.splice(i, 1);
              Toast("已过滤重复图片");
            }
          } else {
            item.url = result.Url;
            let dataImage = {
              OrderId: this.orderInfo.Id,
              Type: 2,
              Image: result.Url,
            };
            let resultImage = await Service.addOrderImage(dataImage);
            item.Id = resultImage.Id;
            Toast("上传成功");
            item.status = "done";
          }
        } catch (error) {
          Toast("文件上传失败：" + error);
          let i = this.imageList.indexOf(item);
          if (i > -1) {
            this.imageList.splice(i, 1);
          }
        }
      });
    },

    async deleteImage(val) {
      let data = {
        Id: val.Id,
        OrderId: this.orderInfo.Id,
      };
      await Service.deleteOrderImage(data);
      Toast.success("删除成功");
    },

    async submit() {
      if (this.imageList.length == 0) {
        let uploader = document.querySelector("#uploader").getBoundingClientRect().y;
        let button = document.querySelector("#button").getBoundingClientRect().height;
        let uploaderInner = document.querySelector("#uploader .van-uploader").getBoundingClientRect().y;
        let uploaderImg = document.querySelector("#uploader .van-uploader__upload").getBoundingClientRect().height;

        uploaderImg += uploaderInner - uploader;

        let temp = document.querySelector("body").clientHeight - uploaderImg - button;
        if (uploader > temp) {
          document.querySelector("#content").scrollTop = uploaderImg;
        }

        Toast("请上传发货凭证");
        return;
      }
      let data = {
        Id: this.orderInfo.Id,
      };
      await Service.shipOrder(data);
      Toast.success("发货成功");
      this.close();
      this.refresh();
    },
  },

  created() { },
  mounted() {
    let watchRoute = this.$watch("$route", () => {
      if (!this.$route.path.startsWith("/shipOrder")) {
        this.show = false;
        watchRoute();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.full-screen {
  ::v-deep {
    .van-dialog__content {
      height: 100%;
    }
  }
}

.page {
  height: 100%;
  overflow: hidden;

  .header {
    padding-bottom: 0;
  }

  .content {
    background-color: white;

    overflow-y: auto;
    padding: 0;
  }

  .van-list {
    padding: 0 10rem;
  }
}

ul {
  li {
    background-color: white;
    margin-bottom: 10rem;
    border-radius: 10rem;
    padding-left: 10rem;
    padding-top: 5rem;

    .title {
      font-size: 14rem;
      font-weight: 900;
      color: black;
      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .product-information {
      margin-top: 10rem;

      .title {
        font-size: 13rem;
        font-weight: bold;
        line-height: 20rem;
        height: 40rem;
      }

      .list-data {
        display: flex;
        padding: 0rem 10rem 10rem 10rem;
        justify-content: space-between;

        .van-image {
          flex-shrink: 0;
          width: 100rem;
          height: 100rem;
          margin: 0 10rem 0 0;
        }

        .state {
          flex: 1;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          .title {
            font-size: 13rem;
            font-weight: bold;
            line-height: 20rem;
            height: 40rem;
          }

          .price {
            display: flex;
            flex-direction: column;
            height: 60rem;
            color: black;
            font-size: 15rem;
            font-weight: bold;
            margin-right: 10rem;
            text-align: end;

            ::v-deep {
              .van-stepper {
                padding-top: 0rem;
              }

              div {
                color: #ee0a24;
                padding-top: 10rem;

                font-weight: 500;
              }
            }
          }
        }

        .freight {
          font-size: 15rem;

          text-align: end;
          padding: 0 20rem 20rem 0;
        }

        .freight+div {
          color: #88898a;
          font-size: 15rem;
          text-align: end;
          padding: 0 20rem 20rem 0;

          :last-child {
            font-weight: 600;
            color: black;
          }
        }
      }

      .total {
        text-align: end;
        margin-right: 22rem;
        font-size: 13rem;
        color: #ee0a24;

        span {
          color: #88898a;
        }
      }
    }

    .item-wrapper {
      background-color: white;
      border-radius: 10rem;
      margin: 10rem 10rem 10rem 0;
      padding: 5rem;

      .label {
        padding: 10rem 10rem 10rem 0;
        font-size: 12rem;
        font-weight: 500;
        display: flex;
        flex-direction: column;

        .address-detail {
          display: flex;
          flex-direction: row;
          padding: 5rem 5rem 5rem 0;
          font-size: 13rem;
          font-weight: 500;

          :first-child {
            display: block;
            width: 85rem;
          }

          :last-child {
            flex: 1;
            min-width: 0;
            word-break: break-all;
          }
        }
      }
    }

    .title {
      font-size: 13rem;
      font-weight: bold;
      line-height: 20rem;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .shipping-info {
      .shipping-address {
        display: flex;
      }
    }

    .uploader {
      display: flex;
      flex-direction: column;
      margin-top: 20rem;

      ::v-deep {
        .van-uploader {
          margin-top: 10rem;
        }
      }
    }
  }
}

.list-btn {
  text-align: right;

  ::v-deep {
    .van-button {
      font-size: 12rem;
      height: 30rem;
      margin-right: 10rem;
      margin-left: 0;
    }

    .van-button--normal {
      padding: 0 15rem;
    }

    .van-button {
      background-color: #f5f7fa;
      color: black;
      border: 0;
    }

    .van-button__content {
      background-color: #f5f7fa;
    }
  }

  .edit {
    color: #009dff;
    background-color: #e8edff;

    :deep(.van-button__content) {
      background-color: #e8edff;
    }
  }
}
</style>
