import Vue from 'vue'
import store from '@/store'
import router from '@/router'

export default {
    getValuesLength(source) {
        let arr = source;
        if (!Array.isArray(source)) {
            arr = source.split(/[\n,，、]/)
        }

        let set = new Set(arr)
        let length = 0

        set.forEach(v => {
            if (v && v.trim()) {
                length++
            }
        })

        return length
    },

    getValues(source, filter) {
        let arr = source;
        if (!Array.isArray(source)) {
            arr = source.split(/[\n,，、]/)
        }

        let set = new Set(arr)
        let arrTemp = [];

        set.forEach(v => {
            let item = v && v.trim()
            if (item) {
                arrTemp.push({
                    value: item,
                    length: item.replace(/[^\x00-\xff]/g, '01').length,
                })
            }
        })

        if (filter) {
            arrTemp = arrTemp.filter(v => filter(v))
        }

        arrTemp = arrTemp.sort((a, b) => {
            return a.length - b.length
        })

        return arrTemp.map(v => v.value)
    },
    getRandomItem(source) {
        if (source.length == 0) {
            return null
        }

        let index = Math.floor(Math.random() * source.length)
        return source[index]
    },

    getRandomList(source, count, minCount) {
        if (source.length == 0) {
            return []
        }

        let arr = source.slice(0)
        let result = []
        let index = 0;
        while (arr.length > 0 && result.length < count) {
            index = Math.floor(Math.random() * arr.length)
            result.push(arr[index])
            arr.splice(index, 1)
        }

        while (result.length < minCount) {
            index = Math.floor(Math.random() * source.length)
            result.push(source[index])
        }

        return result
    },

    createComponent(component, props, target) {
        const vm = new Vue({
            router,
            store,
            // createElement 返回虚拟dom
            render(createElement) {
                // 将 component 作为根组件渲染出来
                // createElement(标签名称或组件配置对象，传递属性、事件等，孩子元素)
                return createElement(component, { props })
            }
        }).$mount(); // 挂载是为了把虚拟dom变成真实dom

        target.appendChild(vm.$el);

        // 实例
        const comp = vm.$children[0];

        // 淘汰机制
        comp.remove = () => {
            console.log('remove');
            // 删除dom
            target.removeChild(vm.$el);
            // 销毁组件
            vm.$destroy();
        }

        // 返回Component组件实例
        return comp;
    },

    copyText(text) {
        let inputDom = document.createElement("textarea");
        inputDom.setAttribute("readonly", "readonly");
        inputDom.value = text;
        document.body.appendChild(inputDom);
        inputDom.select();
        document.execCommand("Copy");
        inputDom.style.display = "none";
        inputDom.remove();

    },

    setToken(token) {
        localStorage.setItem('X-Client-Token', token);
    },

    getToken() {
        return localStorage.getItem('X-Client-Token');
    },

    removeToken() {
        localStorage.removeItem('X-Client-Token');
    },

    isWechat() {
        return /micromessenger/i.test(window.navigator.userAgent);
    },

    getAddressDesc(country, province, city, zipCode) {
        let list = [];

        if (country) {
            list.push(country);
        }

        if (province) {
            list.push(province);
        }

        if (city) {
            list.push(city);
        }

        if (zipCode) {
            list.push(zipCode);
        }

        return list.join(",");
    },

    formatLocation(v, singleLine) {

        if (!v) {
            return "";
        }
        if (singleLine) {
            let list = [];
            if (v.Address1) {
                list.push(v.Address1)
            }
            if (v.Address2) {
                list.push(v.Address2)
            }
            if (v.ZipCode) {
                list.push(v.ZipCode)
            }
            if (v.City) {
                list.push(v.City)
            }
            if (v.Province) {
                list.push(v.Province)
            }
            if (v.CountryDesc) {
                list.push(v.CountryDesc)
            }

            return list.join('，')

        }
        return `收件人姓名：${v.Contact}\n收货地址1：${v.Address1}\n收货地址2：${v.Address2}\n邮编：${v.ZipCode}\n城市：${v.City}\n省份：${v.Province}\n国家：${v.CountryDesc}`;

    },
    maskPhone(value) {
        if (!value) {
            return
        }
        let arr = value.match(/\d/g);
        if ((arr?.length ?? 0) <= 4) {
            return value;
        }
        let left = Math.floor((arr.length - 4) / 2);
        var expression = new RegExp(`((?:\\d\\D*){${left}})(?:\\d)(\\D*)(?:\\d)(\\D*)(?:\\d)(\\D*)(?:\\d)(\\D*)((?:\\d\\D*)*)`);
        console.log(left, expression, value);
        return value.replace(expression, "$1*$2*$3*$4*$5$6")
    }

}