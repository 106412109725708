<template>
  <van-dialog v-model="show" confirm-button-color="#009dff" confirmButtonText="关闭">
    <template #title>
      <div>
        <div class="title">联系我们</div>
      </div>
    </template>
    <div class="van-dialog__message">
      <img :src="require('../assets/images/contact.png')" alt="" srcset="" />
    </div>
    <div class="foot">
      <div>手机/微信/Whatsapp: +86 13537648506</div>
      <div>Email: qingcang111@gmail.com</div>
      <div>地址:广东省深圳市宝安区新桥街道</div>
    </div>
  </van-dialog>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    open() {
      this.show = true;
    },

    created() {},
    mounted() {},
  },
};
</script>

<style lang="scss" scoped>
.van-dialog {
  ::v-deep {
    .van-dialog__header {
      padding: 10rem 0 5rem 0;
      font-weight: 500;
      line-height: 24rem;
      text-align: center;
    }

    .van-dialog__content {
      margin-top: 20rem;

      .van-dialog__message {
        padding: 0;

        img {
          width: 80%;
          max-width: 850rem;
        }
      }
    }
  }
}

.title {
  color: #000;
  font-size: 24rem;
}

.foot {
  font-size: 14rem;
  padding-left: 32rem;

  div {
    margin-top: 15rem;
    text-align: left !important;
  }
}
</style>
