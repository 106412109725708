<template>
    <van-dialog v-if="show" v-model="show" class="full-screen">
        <van-nav-bar title="上传支付凭证" left-text="返回" left-arrow @click-left="close(false)" />

        <van-uploader v-model="imageList" :after-read="afterRead" :before-delete="deleteImage" image-fit="contain" />
        <div class="edit">
            <van-button round block type="danger" color="#009dff" native-type="submit" @click="submit">上传支付凭证</van-button>
        </div>
    </van-dialog>
</template>

<script>
import Service from "@/utils/service";
import { Toast } from "vant";

export default {
    name: "PayOrderImage",
    components: {},
    props: ["forward", "back", "refresh"],
    data() {
        return {
            Id: "",
            show: false,
            imageList: [],
            orderInfo: {},
            address: null,
        };
    },
    computed: {},
    watch: {},
    methods: {
        open(Id, address) {
            this.Id = Id;
            this.address = address;
            this.load();
            this.forward?.call();
            this.show = true;
        },
        async load() {
            let params = {
                id: this.Id,
            };
            let data = await Service.getOrderDetail(params);
            this.orderInfo = data;
            this.imageList = data.ImageList.filter((v) => v.Type == 1).map((v) => {
                return { Id: v.Id, url: v.Image };
            });
        },
        close(shouldClose) {
            this.show = false;
            this.back(shouldClose);
        },

        afterRead(file) {
            let files = [];
            if (Array.isArray(file)) {
                files = file;
            } else {
                files.push(file);
            }
            files.forEach(async (item) => {
                try {
                    item.status = "uploading";

                    let data = {
                        FileName: item.file.name,
                        File: item.file,
                    };
                    let result = await Service.uploadImage(data);
                    if (this.imageList.some((v) => v.url == result.Url)) {
                        let i = this.imageList.indexOf(item);
                        if (i > -1) {
                            this.imageList.splice(i, 1);
                            Toast("已过滤重复图片");
                        }
                    } else {
                        item.url = result.Url;
                        let dataImage = {
                            OrderId: this.orderInfo.Id,
                            Type: 1,
                            Image: item.url,
                        };
                        let resultImage = await Service.addOrderImage(dataImage);
                        item.Id = resultImage.Id;
                        Toast.success("上传成功");
                        item.status = "done";
                    }
                } catch (error) {
                    Toast("文件上传失败：" + error);
                    let i = this.imageList.indexOf(item);
                    if (i > -1) {
                        this.imageList.splice(i, 1);
                    }
                }
            });
        },

        async deleteImage(item) {
            let data = {
                Id: item.Id,
                OrderId: this.orderInfo.Id,
            };
            await Service.deleteOrderImage(data);
            Toast.success("删除成功");
        },
        async submit() {
            if (this.imageList.length == 0) {
                Toast("请上传支付凭证");
                return;
            } else {
                let data = {
                    Id: this.orderInfo.Id,
                    Contact: this.address?.Contact,
                    Phone: this.address?.Phone,
                    Country: this.address?.Country,
                    Province: this.address?.Province,
                    City: this.address?.City,
                    ZipCode: this.address?.ZipCode,
                    Address1: this.address?.Address1,
                    Address2: this.address?.Address2,
                    PayType: 3,
                    WxPayTradeType: "",
                };

                await Service.payOrder(data);
                Toast.success("已上传支付凭证");
                this.close(true);
            }
        },

        mounted() { },
    },
};
</script>

<style lang="scss" scoped>
.van-uploader {
    margin: 20rem 0 0 20rem;
}

.edit {
    margin: 10rem;
    color: #009dff;

    .van-button {
        min-width: 0;
        height: 40rem;
    }
}

.img {
    display: block;
    width: 120rem;
    height: 120rem;
    margin: 10rem auto;
}

.currency {
    color: #ea3a3c;
}

form {
    margin: 0 26rem;
}
</style>
