<!--  -->
<template>
  <div>
    <div id="translateGoogle"></div>
    <van-nav-bar title="商品详情" left-text="返回" left-arrow @click-left="close()">
      <template #right>
        <van-icon name="share-o" size="18" @click="showOverlay = true" />
      </template>
    </van-nav-bar>
    <div class="page">
      <div class="content" ref="scrollRef">
        <div class="head">
          <div class="product-img">
            <van-swipe @change="onImageIndexChange">
              <van-swipe-item v-for="(image, index) in item.ImageList" :key="index">
                <img v-lazy="image" />
              </van-swipe-item>
              <template #indicator>
                <div class="custom-indicator">{{ imageIndex + 1 }}/{{ ImageCount }}</div>
              </template>
            </van-swipe>
          </div>
        </div>
        <div class="product-desc">
          <div class="product-info">
            <div class="product-name">
              <div class="country">
                <span class="discount">{{ item.Discount }}</span>
                <img v-if="item.Country" :src="require(`@/assets/images/flag-${item.Country}.png`)" />
                {{ item.CountryDesc }}-{{ item.WarehouseTypeDesc }}-{{ item.WarehouseType == 1 ? "包邮" : "自提" }}
                <span class="product-name-text">{{ item.Name }}</span>
              </div>
            </div>
          </div>
          <div class="price">
            <span>{{ item.PriceDesc }}</span>
            <span class="line"> {{ item.OriginalPriceDesc }}</span>
          </div>
          <div class="product-store">
            <div>
              <span class="title">库存</span><span>{{ item.Stock }}</span>
            </div>
            <div>
              <span class="title">起订数</span><span>{{ item.MinOrder }}</span>
            </div>
            <div class="adress" v-if="item.WarehouseType == 2 && item.WareHouse">
              <span class="title">地址</span><span class="adress-desc">{{ item.WareHouse }}</span>
            </div>
          </div>
        </div>
        <div class="product">
          <h3>商品介绍</h3>
          <div class="product-content">
            <div class="shade product-shade" ref="shade" :style="{ 'max-height': showMoreProduct ? 'none' : '210rem' }">
              <div v-if="item.Detail" ref="productDetail" class="product-detail" v-html="item.Detail"></div>
              <span v-else style="font-style: oblique; color: #999"> 这个商品没有上传更多信息</span>
            </div>
            <div v-if="item.Detail && showProductDetail" class="more" @click="showMoreProduct = !showMoreProduct">{{ showMoreProduct ? "收起更多" : "查看更多" }} <van-icon style="margin-left: 10rem" :name="showMoreProduct ? 'arrow-up' : 'arrow-down'" /></div>
          </div>
        </div>
        <div class="product">
          <h3>交易规则</h3>
          <div class="product-content rule">
            <div class="shade" :style="{ height: showMore ? 'initial' : '210rem' }">
              <Rule></Rule>
            </div>
            <div class="more" style="margin-bottom: 50rem" @click="showMore = !showMore">{{ showMore ? "收起更多" : "查看更多" }} <van-icon style="margin-left: 10rem" :name="showMore ? 'arrow-up' : 'arrow-down'" /></div>
          </div>
        </div>
      </div>

      <div class="foot">
        <van-goods-action class="btn">
          <van-button class="link" :url="item.Url">{{ item.IsCompetitorUrl ? "竞品链接" : "直达链接" }}&gt;</van-button>
          <van-button class="bid" icon-prefix="iconfont" icon="lightning" :disabled="!item.CanBuy" @click="onBidClick(item)">{{ item.IsSellPrice ? "立即购买" : "快速竞价" }} </van-button>
        </van-goods-action>
      </div>
    </div>
    <van-overlay :show="showOverlay" @click="showOverlay = false">
      <div class="wrapper" @click.stop>
        <van-button type="info" @click="openFacebook">
          <i
            ><svg t="1706074625196" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1616" width="20" height="20">
              <path d="M1024 512c0-282.763636-229.236364-512-512-512C229.236364 0 0 229.236364 0 512s229.236364 512 512 512C794.763636 1024 1024 794.763636 1024 512zM374.504727 512 374.504727 414.021818l60.043636 0L434.548364 354.769455c0-79.918545 23.877818-137.495273 111.383273-137.495273l104.075636 0 0 97.745455-73.262545 0c-36.724364 0-45.056 24.389818-45.056 49.943273l0 49.058909 112.919273 0L629.201455 512l-97.512727 0 0 295.517091L434.548364 807.517091 434.548364 512 374.504727 512z" p-id="1617" fill="#FFFFFF"></path></svg
          ></i>

          <span>facebook</span>
        </van-button>
        <van-button type="danger" @click="openBook">
          <i
            ><svg t="1706074754343" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2112" width="20" height="20">
              <path d="M787.456 442.368h-18.944v41.984h27.136V450.56c0-4.608-3.584-8.192-8.192-8.192zM512 0z" fill="#FFFFFF" p-id="2113"></path>
              <path d="M512 0C229.376 0 0.512 229.376 0.512 512S229.376 1024 512 1024s511.488-228.864 511.488-512c0-282.624-228.864-512-511.488-512z m342.528 414.72c0-14.336 11.776-26.112 26.112-26.112s26.112 11.776 26.112 26.112-11.776 26.112-26.112 26.112h-26.112V414.72zM394.24 460.8l44.032-88.576h51.712l-36.864 70.656c-1.536 3.584 0.512 7.168 4.608 7.168h3.072l3.072-6.144h51.712l-44.544 87.04c-1.536 3.584 0.512 7.168 4.608 7.168h29.696l-23.552 43.008H421.888c-25.6-1.024-9.728-32.256-9.728-32.256l27.136-55.808h-35.84C378.368 492.032 394.24 460.8 394.24 460.8z m-248.32 162.816l-29.696-54.272s17.408-54.272 12.8-125.952H184.32s0.512 163.328-38.4 180.224z m126.976-11.776s-1.536 40.448-36.864 40.448h-17.408s-34.304-3.584-34.304-44.032h34.816V371.712h53.76v240.128z m34.816-168.448h54.784c-4.608 71.68 12.8 125.952 12.8 125.952l-29.696 54.272c-38.4-16.896-37.888-180.224-37.888-180.224z m79.872 208.896c-9.216 0-13.312-6.144-13.312-6.144l26.624-51.2c4.608 4.608 10.752 3.584 10.752 3.584h70.144l-28.16 53.76H387.584z m275.968-0.512H481.792l25.6-51.712h51.2V443.392h-34.304V388.608h121.856v54.784h-33.792v156.672h51.712v51.712z m198.656 0h-13.824c-25.088 0-45.056-19.968-45.056-45.056h50.176v-56.32c0-7.168-5.632-12.8-12.8-12.8h-72.192v114.176h-52.736V537.6h-52.736V483.84h52.736v-41.984h-33.28V388.608h33.28v-16.896h52.736v16.896h20.48c33.28 0 59.904 26.624 59.904 59.904v35.328h6.144c28.672 0 51.712 23.04 51.712 51.712v71.168c0.512 25.088-19.456 45.056-44.544 45.056z" fill="#FFFFFF" p-id="2114"></path></svg
          ></i>
          <span>小红书</span>
        </van-button>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import BidDialog from "./BidDialog.vue";
import PayDialog from "./PayDialog.vue";
import AfterSales from "@/components/AfterSales";
import Rule from "@/components/Rule";

import { Toast, ImagePreview, Lazyload } from "vant";

export default {
  name: "ProductDetail",
  components: { Rule },
  props: [],

  data() {
    return {
      showOverlay: false,
      showMore: false,
      showMoreProduct: false,
      showProductDetail: false,
      item: {
        Country: "",
      },

      imageIndex: 0,
    };
  },
  computed: {
    ImageCount() {
      return this.item.ImageList?.length ?? 0;
    },
  },
  watch: {
    item(val) {
      if (val) {
        this.setTitle();
      }
    },
  },
  methods: {
    async openBook() {
      let result = await Service.getJsShareInfo();
      let item = this.item;
      let title = `${item.Discount}  ${item.CountryDesc}-${item.WarehouseTypeDesc}-${item.WarehouseType == 1 ? "包邮" : "自提"}  ${item.Name}`;
      let content = `国家：${item.CountryDesc}\n仓库：${item.WarehouseTypeDesc}-${item.WarehouseType == 1 ? "包邮" : "自提"}\n库存：${item.Stock}\n商品：${item.Name}`;
      xhs.share({
        shareInfo: {
          type: "normal", // 必填，笔记类型 'video' | 'normal'
          title: title, // 笔记标题
          content: content, // 笔记正文
          images: this.item.ImageList, //图文类型必填，笔记图片，必须是服务器地址，暂时不支持本地文件
          // video: " ", // 视频类型必填，必须是服务器地址
          // cover: " ", // 视频封面图，必须是服务器地址，暂时不支持本地文件
        },
        verifyConfig: {
          appKey: result.AppKey, //必填，应用的唯一标识,
          nonce: result.Nonce, // 必填，服务端生成签名的随机字符串
          timestamp: result.Timestamp, // 必填，服务端生成签名的时间戳
          signature: result.Signature, // 必填，服务端生成的签名
        },
        fail: (e) => {
          console.log(e);
          // 调用失败时执行的回调函数
        },
      });
    },

    onImageIndexChange(index) {
      this.imageIndex = index;
    },
    async getHomeProductDetail() {
      let result = await Service.getHomeProductDetail({ id: this.$route.query.id });
      this.item = result;
      if (this.item.Detail) {
        this.observeProductDetail();
      }
    },

    openFacebook() {
      var Url = `https://www.qingcang1.com/api/share/product?id=${this.item.Id}`;
      window.open(`https://www.facebook.com/sharer.php?&u=${encodeURIComponent(Url)}&share=1&source=null`);
    },

    close() {
      this.$router.replace("/");
    },

    imgPreview() {
      ImagePreview([this.image]);
    },

    async onBidClick() {
      this.item.Image = this.item.ImageList[0];
      if (this.item.IsSellPrice) {
        var payDialog = Util.createComponent(
          PayDialog,
          {
            forward: () => {
              this.$router.push("/productDetail/payDialog");
            },
            back: () => {
              this.$router.back();
            },
          },
          document.querySelector("#app")
        );
        payDialog.open(this.item);
      } else {
        var dialog = Util.createComponent(
          BidDialog,
          {
            forward: () => {
              this.$router.push("/productDetail/bidDialog");
            },
            back: () => {
              this.$router.back();
            },
          },
          document.querySelector("#app")
        );
        dialog.open(this.item);
      }
    },
    showContact() {
      let dialog = Util.createComponent(
        AfterSales,
        {
          title: "联系售后",
          confirmButtonText: "确认",
        },
        document.querySelector("#app")
      );
      dialog.open();
    },
    setTitle() {
      let item = this.item;
      let title = `${item.Discount}  ${item.CountryDesc}-${item.WarehouseTypeDesc}-${item.WarehouseType == 1 ? "包邮" : "自提"} ${item.Name}`;
      if (item.Name) {
        if (document.title != title) {
          document.title = title;
        }
      }
    },
    observeProductDetail() {
      let productNode = this.$refs.shade;
      let options = {
        childList: true,
      };

      let productDetail = this.$refs.productDetail;
      let observeImage = false;

      if (productDetail) {
        observeImage = this.observeDetailImage();
      }

      if (!observeImage) {
        let observer = new MutationObserver((mutationRecoards, observer) => {
          this.observeDetailImage();
        });
        // 对观察者添加需要观察的元素，并设置需要观察元素的哪些方面
        observer.observe(productNode, options);
      }
    },

    observeDetailImage() {
      let productNode = this.$refs.shade;
      let productDetail = this.$refs.productDetail;
      if (!productDetail) {
        return false;
      }

      if (productDetail.clientHeight > productNode.clientHeight) {
        this.showProductDetail = true;
        return true;
      }

      let imgNodeList = document.querySelectorAll(".product-detail img");
      if (imgNodeList.length > 0) {
        for (let i = 0; i < imgNodeList.length; i++) {
          imgNodeList[i].onload = () => {
            if (this.showProductDetail) {
              return;
            }
            if (productDetail.clientHeight > productNode.clientHeight) {
              this.showProductDetail = true;
            } else {
              this.showProductDetail = false;
            }
          };
        }
        return true;
      } else {
        this.showProductDetail = false;
      }

      return false;
    },
  },

  created() {},
  mounted() {
    let path = this.$route.path;
    if (path.startsWith("/productDetail") && !this.$route.query.id) {
      this.$router.push("/");
    }
    this.oldTitle = document.title;
    this.getHomeProductDetail();

    let translateParent = document.querySelector("#translateGoogle");
    let translateGoogle = document.querySelector("#google_translate_element");
    if (translateGoogle && translateParent) {
      this.translateGoogle = translateGoogle;
      translateParent.appendChild(translateGoogle);
    }
  },

  beforeDestroy() {
    if (this.translateGoogle) {
      document.querySelector("body").appendChild(this.translateGoogle);
    }
  },
  destroyed() {
    document.title = this.oldTitle;
  },
};
</script>

<style lang="scss">
.product-shade {
  word-break: break-all;

  p {
    display: block;
  }

  table {
    width: 100% !important;
  }

  img {
    width: 100% !important;
    height: 100%;
    object-fit: contain;
  }
}
</style>

<style lang="scss" scoped>
::v-deep {
  .van-overlay {
    .wrapper {
      margin: 100rem auto 10rem auto;
      width: 280rem;

      .van-button {
        border-radius: 4rem;
        margin-right: 10rem;
        width: 130rem;
        height: 48rem;

        .van-button__text {
          display: flex;
          justify-content: space-around;
          width: 100%;

          i {
            margin-top: 3rem;
          }

          span {
            line-height: 24rem;
          }
        }
      }
    }
  }
}

.head {
  .product-img {
    width: 100%;
    height: 390rem !important;
    display: flex;
    justify-content: center;
    align-items: center;

    /deep/ .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;
      background-color: #39a9ed;
    }

    .van-swipe {
      width: 100%;
      height: 100%;

      .custom-indicator {
        position: absolute;
        right: 5rem;
        bottom: 5rem;
        padding: 2rem 5rem;
        font-size: 20rem;
        background: rgba(0, 0, 0, 0.1);
      }

      .van-swipe__track {
        width: 100%;

        .van-swipe-item {
          display: flex;
          justify-content: center;
        }

        img {
          display: flex;
          height: 100%;
          max-height: 100%;
          max-width: 100%;
          border-style: none;
        }
      }
    }
  }
}

.content {
  padding: 0 !important;
  overflow: scroll;
  background-color: #fff;

  .product-desc {
    margin-bottom: 10rem;
    padding: 12rem 10rem;
    background-color: #fff;
    border-radius: 10rem;

    .price {
      margin-top: 10rem;
      color: #f2270c;
      display: inline-block;
      font-size: 22rem;
      line-height: 22rem;
      font-weight: 700;

      .line {
        font-size: 14rem;
        color: #999;
        text-decoration: line-through;
        margin-left: 8rem;
        font-weight: 400;
      }
    }

    .product-store {
      text-align: left;

      div {
        margin-top: 5px;
      }

      span {
        font-size: 15px;
        margin-left: 0;
        margin-right: 14px;
      }

      .adress {
        display: flex;

        .title {
          display: inline-block;
          width: 70rem;
        }

        .adress-desc {
          flex: 1;
          display: inline-block;
        }
      }

      .title {
        display: inline-block;
        width: 70rem;
      }
    }

    .product-info {
      .product-name {
        .country {
          font-size: 14rem;
          display: flex;
          align-items: center;
          overflow: hidden;
          white-space: nowrap;
          font-weight: 700;
          color: #333;

          .product-name-text {
            margin-left: 10rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .discount {
            height: 15rem;
            line-height: 15rem;
            background: #ea3a3c;
            border-radius: 2rem;
            font-size: 10rem;
            color: #fff;
            padding: 0 2rem;
          }

          img {
            height: 15rem;
            margin: 0 7rem;
            border: 1rem solid #ccc;
          }
        }
      }
    }
  }

  .product {
    padding: 0 16rem;
    background-color: #fff;

    h3 {
      padding-top: 16rem;
      font-size: 16rem;
      font-weight: 700;
      line-height: 19rem;
      color: #333;
    }

    .product-content {
      margin-top: 12rem;

      /deep/ .shade {
        overflow: hidden;
        list-style: disc outside none;
        font-size: 14rem;
        color: #666;

        .product-detail {
          overflow: hidden;
        }

        .page {
          .content {
            overflow-y: visible !important;
          }
        }
      }

      .more {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34rem;
        border-radius: 6rem;
        border: 1rem solid #eee;
        font-size: 14rem;
        font-family: Helvetica;
        color: #666;
        line-height: 14rem;
        margin: 16rem 0;
      }
    }
  }
}

.ssd-module-mobile-wrap {
  position: relative;
  margin: 0auto;
  width: 375rem;
  text-align: left;
  background-color: #fff;

  .ssd-module {
    width: 375rem;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 100%;
  }
}

.foot {
  .btn {
    box-shadow: 0 0 12px 1px #9bb4d480;
    height: 72rem;
    display: flex;
    justify-content: space-around;

    .link {
      flex: 1;
      margin-left: 10rem;
      margin-right: 10rem;
      border: 1rem solid rgba(0, 157, 255, 0.3);
      color: #009dff;
      background: rgba(0, 157, 255, 0.1);
      height: 40rem;
      font-size: 14rem;
      font-weight: 700;
      border-radius: 10rem;
    }

    .bid {
      flex: 1;
      border: 1rem solid #ea3a3c;
      color: #fff;
      background-color: #ea3a3c;
      height: 40rem;
      font-size: 14rem;
      font-weight: 700;
      border-radius: 10rem;
      margin-right: 10rem;

      .iconfont {
        margin-right: -2rem;
      }
    }

    .van-button--disabled {
      background-color: #999;
      border: 1rem solid #999;
    }
  }
}
</style>
