<template>
  <van-dialog v-model="show" :title="title" show-cancel-button :confirmButtonText="confirmButtonText" confirm-button-color="#009dff" :before-close="beforeClose">
    <template #title>
      <div>
        <div class="icon-wrapper">
          <van-icon class-prefix="iconfont" name="warning-filling" color="#fca136" size="35rem" />
        </div>
        <div class="title">
          {{ title }}
        </div>
      </div>
    </template>
    <div class="van-dialog__message van-dialog__message--has-title">
      {{ message }}
    </div>
</van-dialog>
</template>

<script>
export default {
  components: {},
  props: ["title", "message", "confirmButtonText", "confirm"],
  data() {
    return {
      show: false,
      item: {},
      price: "",
      num: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(item) {
      this.item = item;
      this.show = true;
    },

    beforeClose(action, done) {
      if (action === "confirm") {
        this.confirm?.();
        done();
      } else {
        done();
      }
    },

    created() { },
    mounted() { },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  margin-bottom: 4rem;
}

.title {
  color: #000;
}
</style>
