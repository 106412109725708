<template>
  <van-dialog v-model="show" v-if="show" :class="showBack ? 'full-screen' : ''">
    <van-nav-bar :title="title" :left-text="showBack ? '返回' : ''" :left-arrow="showBack" @click-left="close()" />

    <van-form ref="form" class="dialog-content" label-width="50rem" @submit="onSubmit">
      <div v-if="hint" class="hint">
        {{ hint }}
      </div>

      <van-field v-model="phone" label="电话" required placeholder="请填写电话" :rules="[{ required: true, message: '请填写电话' }]" />
      <van-field v-model="wechat" label="微信号" required placeholder="请填写微信号" :rules="[{ required: true, message: '请填写微信号' }]" />

      <div style="margin: 16rem">
        <van-button round block type="danger" color="#009dff" native-type="submit">保存</van-button>
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";

export default {
  components: {},
  props: ["forward", "back", "title", "hint", "showBack"],
  data() {
    return {
      show: false,

      phone: "",
      wechat: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    open() {
      this.forward?.call();
      this.show = true;
      this.load();
    },

    close() {
      this.show = false;
      this.back?.call();
    },

    async load() {
      let response = await Service.getContact();

      this.phone = response.Phone;
      this.wechat = response.Wechat;
    },

    onSubmit() {
      this.$refs.form
        .validate()
        .then(async () => {
          let data = {
            Phone: this.phone,
            Wechat: this.wechat,
          };

          await Service.editContact(data);

          this.close();
        })
        .catch(() => {});
    },

    mounted() {},
  },
};
</script>

<style lang="scss" scoped>
.hint {
  color: #666;
  font-size: 15rem;
  margin: 20rem 15rem;
}

:deep(.van-cell--required::before) {
  position: relative;
  left: auto;
  right: 3rem;
}

:deep(.van-dialog__footer) {
  display: none;
}
</style>
