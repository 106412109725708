<template>
  <div class="tinymce-box">
    <editor v-model="myValue.Detail" tinymce-script-src="/tinymce/tinymce.min.js" :init="init"> </editor>
  </div>
</template>

<script>
// import tinymce from 'tinymce/tinymce' //tinymce默认hidden，不引入不显示
import Editor from "@tinymce/tinymce-vue";
import Service from "@/utils/service";

// // 编辑器插件plugins
export default {
  components: {
    Editor,
  },
  name: "TinymceEditor",
  props: {
    detail: {
      type: Object,
    },
    refresh: {
      type: Function,
    },
    plugins: {
      type: [String, Array],
      default: "  advlist autolink  lists link image charmap print preview anchor \
                        searchreplace visualblocks code fullscreen\
                        insertdatetime media table paste code help wordcount",
    },
    toolbar: {
      type: [String, Array],
      default: "code undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | lists image media table | removeformat",
    },
  },
  data() {
    return {
      init: {
        language_url: "/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "/tinymce/skins/content/default/content.css",
        content_style: "img {object-fit: contain;object-fit: contain;vertical-align: middle;}",
        height: 550,
        plugins: "indent2em code print paste preview autolink fullscreen image link media table charmap hr pagebreak anchor insertdatetime advlist lists wordcount bdmap indent2em axupimgs",
        toolbar: ["code undo redo | cut copy paste pastetext | forecolor backcolor bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent", "styleselect formatselect fontselect fontsizeselect | bullist numlist | blockquote subscript superscript removeformat", "table image axupimgs media charmap hr pagebreak insertdatetime print preview | fullscreen | bdmap indent2em lineheight"],
        font_formats: "微软雅黑='微软雅黑';宋体='宋体';黑体='黑体';仿宋='仿宋';楷体='楷体';隶书='隶书';幼圆='幼圆';Andale Mono=andale mono,times;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier;Georgia=georgia,palatino;Helvetica=helvetica;Impact=impact,chicago;Symbol=symbol;Tahoma=tahoma,arial,helvetica,sans-serif;Terminal=terminal,monaco;Times New Roman=times new roman,times;Trebuchet MS=trebuchet ms,geneva;Verdana=verdana,geneva;Webdings=webdings;Wingdings=wingdings",
        statusbar: true, // 底部的状态栏
        menubar: false, // 最上方的菜单
        branding: false, // 水印“Powered by TinyMCE”
        paste_data_images: true,
        paste_as_text: true,

        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          var file = blobInfo.blob();
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async function () {
            const Data = reader.result.split(",")[1];
            const params = { FileName: file.name, File: file };
            let result = await Service.uploadImage(params);
            success(result.Url);
          };
        },
        file_picker_callback: function (callback, value, meta) {},
        paste_preprocess: function (plugin, args) {},
        fontsize_formats: "12rem 14rem 16rem 18rem 24rem 36rem 48rem 56rem 72rem",
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: "",
        invalid_styles: {
          img: "width height",
        },
        image_dimensions: false,
      },
      myValue: {
        Detail: "",
      },
    };
  },
  mounted() {},
  methods: {},
  created() {},
  watch: {
    detail(val) {
      this.myValue = val;
    },
  },
};
</script>
<style>
.tox-silver-sink {
  z-index: 2000009 !important;
}
</style>
<style lang="scss" scoped>
.upload-demo {
  ::v-deep {
    .el-upload-list {
      display: none !important;
    }
  }
}
</style>
