<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="content">
      <div class="path">库存管理 / <span>库存发布</span></div>
      <ProductEditor ref="productEditor" addAddressUrl="/addProduct/address" @submit="onSubmit" />
    </div>
  </div>
</template>
<script>
import PageTop from "@/components/PageTop";
import ProductEditor from "./ProductEditor";
import Util from "@/utils/util";
import Service from "@/utils/service";
import { Toast } from "vant";

export default {
  components: {
    PageTop,
    ProductEditor,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},

  methods: {
    onSubmit(flag) {
      if (flag == 0) {
        Toast.success("保存成功");
      } else if (flag == 1) {
        this.$router.push("/addProductSuccess");
      }
    },
  },

  created() { },
  mounted() {
    if (this.$route.path !== "/addProduct") {
      this.$router.replace("/addProduct");
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  .content {
    padding: 0;

    .path {
      padding-left: 10rem;
    }
  }
}
</style>
