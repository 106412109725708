<!--  -->
<template>
  <div class="page">
    <div class="content">
      <p>&nbsp;</p>
      <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">Q &amp; A</span></p>
      <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">1、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">关于清仓易</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">全站点跨境库存商品清仓服务的在线交易网站。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站最早于<span lang="EN-US">2021</span>年<span lang="EN-US">9</span>月上线试运营，使用差价模式进行交易，依托过往的运营经验，于<span lang="EN-US">2023</span>年<span lang="EN-US">3</span>月焕新升级为佣金模式。同时对网站布局及交互方式进行了较大的调整，但我们服务真诚的服务不会变，郑重承诺：</span>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">全站清仓，童叟无欺；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">通过搜索、社交、群组等多渠道爆光；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">免费注册、免费发布，不成交零费用；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">价格透明，无差价；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">买卖双方交易真实可靠，实时沟通；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c; color: #1a1b1c">平台担保，货物和资金安全有保障。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">2、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">账号和注册</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站焕新升级，彻底重构了注册、发布系统，并增加了在线交易系统。因此</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如果您在旧的网站上注册过，微信扫码关注公众号即可重新注册并登录；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如果您是买家，此前未注册，也请您微信扫码关注公众号即可完成注册并登录；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如果您是新用户，直接微信扫码即可完成注册并登录。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">为什么要进行注册？</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">作为卖家，您可以免费注册，免费发布商品，且能实时获得买家的报价；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">作为买家，旧的网站通过商品截图与站长沟通，效率不高；新网站注册后，可直接对中意的商品进行竞价并协商价格；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">作为清仓易，我们希望买卖双方直接进行价格磋商，减少中间商，无差价，提高交易效率，让库存商品快速流转起来，真正造福买卖双方，实现多方共赢。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">3、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品发布</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">为避免货不对板，卖家须如实填写商品真实信息，特别是商品销售链接，如链接确已下架的，必须注明链接为竞品链接。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如遇竞品链接，清仓易强烈建议买家联系客服并尝试获取样品后再进行交易；</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站对商誉良好的用户及信息完善且真实的商品将予以优先推广和曝光。</span></p>
      <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">4、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品运费</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">对于亚马逊<span lang="EN-US">FBA</span>仓的库存商品，通常报价含运费<span lang="EN-US">(</span>仅限库存所在国家<span lang="EN-US">)</span>；</span>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">对于海外仓的库存商品，则需买家上门提货，请买家根据自己的情况自行选择。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">以上信息请买卖双方知悉，如遇特殊情况，请在商品发布时注明或与客服人员联系。</span></p>
      <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; color: #1a1b1c">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">5、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">服务费用</span></strong>
      </p>
      <div align="center">
        <table class="MsoTableGrid" style="border: none" border="1" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td style="width: 55pt; border-width: 1pt; border-color: windowtext; border-image: initial; background: #00b0f0; padding: 0cm 5.4pt" rowspan="2">
                <p class="MsoNormal" style="text-align: center" align="center">
                  <strong><span style="font-family: 宋体; color: black">服务项目</span></strong>
                </p>
              </td>
              <td style="width: 80pt; border-top-width: 1pt; border-right-width: 1pt; border-bottom-width: 1pt; border-top-color: windowtext; border-right-color: windowtext; border-bottom-color: windowtext; border-image: initial; border-left: none; background: #00b0f0; padding: 0cm 5.4pt" colspan="2">
                <p class="MsoNormal" style="text-align: center" align="center">
                  <strong><span style="font-family: 宋体; color: black">收费标准</span></strong>
                </p>
              </td>
              <td style="border-top-width: 1pt; border-right-width: 1pt; border-bottom-width: 1pt; border-top-color: windowtext; border-right-color: windowtext; border-bottom-color: windowtext; border-image: initial; border-left: none; background: #00b0f0; padding: 0cm 5.4pt" rowspan="2">
                <p class="MsoNormal" style="text-align: center" align="center">
                  <strong><span style="font-family: 宋体; color: black">备注</span></strong>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; background: #00b0f0; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center">
                  <strong><span style="font-family: 宋体; color: black">我卖的</span></strong>
                </p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; background: #00b0f0; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center">
                  <strong><span style="font-family: 宋体; color: black">我买的</span></strong>
                </p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">用户注册</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
              </td>
            </tr>
            <tr style="mso-yfti-irow: 3">
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品发布</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">-</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品展示</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">-</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品推广</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">-</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">商品竞价</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">-</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">样品订单</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">免费</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">10</span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">人民币</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">按样品订单收取</span></p>
              </td>
            </tr>
            <tr>
              <td style="border-right-width: 1pt; border-bottom-width: 1pt; border-left-width: 1pt; border-right-color: windowtext; border-bottom-color: windowtext; border-left-color: windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">大货订单</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">5%</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal" style="text-align: center" align="center"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">5%</span></p>
              </td>
              <td style="border-top: none; border-left: none; border-bottom-width: 1pt; border-bottom-color: windowtext; border-right-width: 1pt; border-right-color: windowtext; padding: 0cm 5.4pt" valign="top">
                <p class="MsoNormal"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">按照交易金额收取</span></p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">6、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">付款及收款</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">为充分保障买卖双方的权益，杜绝发货不给钱以及给钱不发货等不良现象。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">清仓易要求买家拍下商品后必须进行全额付款，此阶段款项将由清仓易代为保管</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">- </span><span style="font-family: 宋体; mso-bidi-font-family: 宋体">待卖家充分履行义务后，由卖家向平台发起收款</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">清仓易秉承诚信经营的原则，既要保证买家预付款的资金安全，同时也需对卖家货款的资金担保以及卖家发货义务的约束。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">支付方式</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站目前支持微信转账、中国大陆支付宝转账、中国大陆银行卡转账<span lang="EN-US">(</span>如需公司转账请与客服人员联系<span lang="EN-US">)</span>、<span lang="EN-US">Payoneer</span>外币转账<span lang="EN-US">(</span>目前仅支持美元、欧元、英镑、日元<span lang="EN-US">)</span>等支付方式。</span>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">强烈推荐建议使用人民币进行交易，如确需外币交易的，请与客服人员联系。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <strong
          ><span style="font-family: 宋体; mso-bidi-font-family: 宋体">发票和<span lang="EN-US">Invoice</span></span></strong
        >
      </p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站上发布的库存商品有其特殊性，如未特别注明均为不含税标价，如需发票或<span lang="EN-US">Invoice</span>请与客服联系。</span>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">7、</span
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">售后商品数量认定</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如遇交易纠纷，请及时与客服联系，由双方提交各自证据，清仓易将持中立、公平的原则，汇同买卖双方进行友好协商解决。未决的情况下由清仓易最终仲裁。</span></p>
      <p class="MsoNormal"><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></p>
      <p class="MsoNormal" style="text-indent: 21pt">
        <span style="font-family: 宋体; mso-bidi-font-family: 宋体">卖家有义务保证库存订单商品及外包装的完整性，且不能是<span lang="EN-US">LPN</span>商品（亚马逊退货商品），确保不受影响商品的二次销售。</span>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">买家收货后发现影响二次销售的商品，有权要求剔除，在结算时减去此类商品，但买家必须进行拍照并上传，售后取证照片必须包含商品条码或其他可唯一标识的记号，否则卖家有权拒绝买家主张。</span></p>
      <p class="MsoNormal">
        <strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></strong>
      </p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">8、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">网站免责和用户处罚</span></strong>
      </p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">清仓易作为一个外贸尾货清仓服务网站，暂不提供商品存储、运输、检测、维修等服务。不接触实体商品，也不对商品的真伪、质量进行评估和鉴定，本质上商品交易行为由买卖双方决定，如遇纠纷，清仓易至多承担当次交易所获利润的责任，因此也呼吁买卖双方诚信交易。</span></p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">如网站发现用户利用网站漏洞恶意扰乱交易，恶意发布，商品货不对板等违规行为的，网站有权利对用户账号进行包括但不限于短期或长期的停用账户、禁止发布、禁止交易等一切有必要的措施。</span></p>
      <p class="MsoNormal" style="text-align: center" align="center">&nbsp;</p>
      <p class="MsoNormal" style="text-indent: 21pt"><span style="font-family: 宋体; mso-bidi-font-family: 宋体">请大家一起维护干净、透明、公平、诚信的外贸库存清仓交易。</span></p>
      <p class="MsoNormal">
        <strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">&nbsp;</span></strong>
      </p>
      <p class="MsoNormal" style="margin-left: 0cm; text-indent: 0cm; mso-list: l0 level1 lfo1">
        <!-- [if !supportLists]--><strong><span lang="EN-US" style="font-family: 宋体; mso-bidi-font-family: 宋体">9、</span></strong
        ><!--[endif]--><strong><span style="font-family: 宋体; mso-bidi-font-family: 宋体">清仓易平台交易流程图</span></strong>
      </p>

      <img class="flow" :src="require('@/assets/images/flow.png')" />
      <p>&nbsp;</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.content {
  margin: 10rem 0;
  font-size: 13rem;

  .flow {
    width: 100%;
  }
}
</style>
