<!--  -->
<template>
  <van-form class="form">
    <div class="title">基本信息</div>
    <div class="field-set">
      <div class="label required">商品名称</div>
      <van-field class="field" v-model="name" name="商品名称" placeholder="请输入商品名称，不超过12个汉字" />

      <div class="label required">库存所在国家</div>
      <van-field class="field" is-link readonly :value="countryDesc" placeholder="请选择国家" @click="countrySheetShow = true"> </van-field>
      <van-action-sheet v-model="countrySheetShow" :actions="countryList" close-on-click-action @select="onCountrySelect" />

      <div class="label required">仓库类型</div>
      <van-field class="field" is-link readonly :value="warehouseTypeDesc" placeholder="请选择仓库类型" @click="warehouseTypeSheetShow = true" />
      <van-action-sheet
        v-model="warehouseTypeSheetShow"
        :actions="warehouseTypeList"
        close-on-click-action
        @select="
          warehouseType = $event.value;
          warehouseTypeDesc = $event.name;
        "
      />

      <div v-if="warehouseType == 2">
        <div class="label required">仓库地址</div>
        <div class="field-line">
          <van-field class="field" is-link readonly :value="warehouseDesc" placeholder="请选择仓库地址" @click="warehouseSheetShow = true" />
          <van-button icon="plus" @click="addAddress">新增海外仓</van-button>
        </div>
      </div>
      <van-action-sheet v-model="warehouseSheetShow" :actions="warehouseList" close-on-click-action @select="onWarehouseSelect" />

      <div class="label required">库存商品类目</div>
      <van-field class="field" is-link readonly :value="categoryDesc" placeholder="请选择商品类目" @click="categorySheetShow = true"></van-field>
      <van-action-sheet
        v-model="categorySheetShow"
        :actions="categoryList"
        close-on-click-action
        @select="
          category = $event.value;
          categoryDesc = $event.name;
        "
      />

      <div class="label required">库存商品图片</div>
      <van-uploader v-model="imageList" multiple accept="image/png, image/jpeg" image-fit="contain" :max-count="5" :max-size="5 * 1024 * 1024" :after-read="afterRead" @oversize="onOversize">
        <div class="uploader-content">
          <van-icon class-prefix="iconfont" name="plus"></van-icon>
          <div class="uploader-text">上传图片</div>
        </div>
      </van-uploader>
      <div class="hint">至少上传1张图片，最多5张</div>

      <div class="label required">原销售链接</div>
      <van-field class="field" v-model="url" name="原销售链接" placeholder="请输入原销售链接" />
      <van-checkbox class="hint-checkbox" v-model="isCompetitorUrl" shape="square">此链接非该商品真实销售链接，显示为竞品链接 </van-checkbox>
    </div>

    <div class="title">商品定价</div>
    <div class="field-set">
      <div class="label required">原价</div>
      <van-field class="field" v-model="originalPrice" type="number" name="原价" placeholder="请输入商品原价格" />

      <div class="label required">清仓价</div>
      <van-field class="field" v-model="price" type="number" name="清仓价" placeholder="请输入清仓价" />
      <van-checkbox class="hint-checkbox" v-model="isSellPrice" shape="square">一口价，不支持买家议价 </van-checkbox>

      <div class="label required">币种</div>
      <van-field class="field" is-link readonly :value="currencyDesc" @click="currencySheetShow = true" placeholder="请选择货币单位"></van-field>
      <van-action-sheet
        v-model="currencySheetShow"
        :actions="currencyList"
        close-on-click-action
        @select="
          currency = $event.value;
          currencyDesc = $event.name;
        "
      />
      <div class="hint">默认情况与所选库存所在国家货币一致</div>
    </div>

    <div class="title">商品库存</div>
    <div class="field-set">
      <div class="label required">库存总数</div>
      <van-field class="field" v-model="stock" type="number" name="库存总数" placeholder="请输入库存总数" />

      <div class="label required">起订数</div>
      <van-field class="field" v-model="minOrder" type="number" name="起批数" placeholder="请输入起批数" />
    </div>
    <div class="title">商品介绍</div>
    <div class="field-set">
      <Tinymce :detail="detail"></Tinymce>
    </div>

    <div class="button-wrap">
      <van-button type="info" @click="submit(1)">提交发布</van-button>
      <van-button plain type="info" @click="submit(0)">保存草稿</van-button>
    </div>
  </van-form>
</template>

<script>
import { Toast } from "vant";
import EditAddress from "@/views/Address/EditAddress";
import EditContact from "@/views/Contact/EditContact.vue";
import Service from "@/utils/service";
import Util from "@/utils/util";
import Tinymce from "./Tinymce.vue";

export default {
  components: { Tinymce },
  props: ["addAddressUrl"],
  data() {
    return {
      countrySheetShow: false,
      warehouseTypeSheetShow: false,
      warehouseSheetShow: false,
      categorySheetShow: false,
      currencySheetShow: false,

      flag: 0,
      remark: "",

      id: "",
      name: "",
      price: "",
      originalPrice: "",
      isSellPrice: false,

      url: "",
      isCompetitorUrl: false,
      detail: {},
      stock: "",
      minOrder: 30,

      imageList: [],

      country: this.$store.state.countryList[0].Id,
      countryDesc: this.$store.state.countryList[0].Name,
      countryList: [
        ...this.$store.state.countryList.map((v) => {
          return { name: v.Name, value: v.Id, currency: v.currency };
        }),
      ],

      category: "",
      categoryDesc: "",
      categoryList: [
        ...this.$store.state.categoryList.map((v) => {
          return { name: v.Name, value: v.Id };
        }),
      ],

      warehouseType: "",
      warehouseTypeDesc: "",
      warehouseTypeList: [
        { name: "FBA", value: 1 },
        { name: "海外仓", value: 2 },
      ],

      warehouse: "",
      warehouseDesc: "",
      warehouseList: [],
      warehouseAddress: null,

      currency: this.$store.state.currencyList[1].Id,
      currencyDesc: this.$store.state.currencyList[1].Name,
      currencyList: [
        ...this.$store.state.currencyList.map((v) => {
          return { name: v.Name, value: v.Id };
        }),
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    refresh() {
      this.refreshAddressList();
    },

    async refreshAddressList() {
      let result = await Service.getAddressList({
        pageSize: 100,
      });

      this.warehouseList = result.Rows.map((v) => {
        return {
          name: v.Desc,
          value: v.Id,
          item: {
            country: v.Country,
            countryDesc: v.CountryDesc,
            province: v.Province,
            city: v.City,
            zipCode: v.ZipCode,
            contact: v.Contact,
            phone: v.Phone,
            address1: v.Address1,
            address2: v.Address2,
          },
        };
      });

      this.syncWarehouseAddress();
    },

    async load(id) {
      this.id = id;

      let result = await Service.getProductDetail({
        id: id,
      });

      this.flag = result.Flag;
      this.remark = result.Remark;
      this.name = result.Name;
      this.detail = result;
      this.imageList = result.ImageList.map((v) => {
        return { url: v };
      });

      this.category = result.Category;
      this.categoryDesc = result.CategoryDesc;

      this.country = result.Country;
      this.countryDesc = result.CountryDesc;

      this.warehouseType = result.WarehouseType;
      this.warehouseTypeDesc = result.WarehouseTypeDesc;

      if (result.WarehouseType == 1) {
        this.warehouseAddress = null;
      } else {
        this.warehouseAddress = {
          country: result.Country,
          countryDesc: result.CountryDesc,
          province: result.Province,
          city: result.City,
          zipCode: result.ZipCode,
          contact: result.Contact,
          phone: result.Phone,
          address1: result.Address1,
          address2: result.Address2,
        };
      }

      this.stock = result.Stock;
      this.minOrder = result.MinOrder;
      this.price = result.Price;
      this.originalPrice = result.OriginalPrice;
      this.isSellPrice = result.IsSellPrice;
      this.currency = result.Currency;
      this.currencyDesc = result.CurrencyDesc;
      this.url = result.Url;
      this.isCompetitorUrl = result.IsCompetitorUrl;

      this.syncWarehouseAddress();
    },

    async submit(flag) {
      if (flag == 1) {
        if (!this.name) {
          Toast.fail("请填写商品名称");
          return;
        }
        if (!this.country) {
          Toast.fail("请选择库存所在国家");
          return;
        }
        if (!this.warehouseType) {
          Toast.fail("请选择仓库类型");
          return;
        }
        if (this.warehouseType == 2 && !this.warehouse && this.warehouse !== 0) {
          Toast.fail("请选择仓库地址");
          return;
        }
        if (!this.category) {
          Toast.fail("请选择库存商品类目");
          return;
        }
        if (this.imageList.length == 0) {
          Toast.fail("请上传库存商品图片");
          return;
        }
        if (!this.url) {
          Toast.fail("请填写原销售链接");
          return;
        }
        if (!this.originalPrice) {
          Toast.fail("请输入商品原价格");
          return;
        }
        if (!this.price) {
          Toast.fail("请输入清仓价");
          return;
        }
        if (!this.currency) {
          Toast.fail("请选择货币单位");
          return;
        }
        if (!this.stock) {
          Toast.fail("请填写库存总数");
          return;
        }
        if (!this.minOrder) {
          Toast.fail("请填写起订数");
          return;
        }
      }

      let address = null;
      if (this.warehouseType == 2) {
        address = this.warehouseList.find((v) => v.value == this.warehouse)?.item;
      }

      if (address && this.country != address.country) {
        Toast.fail("仓库所在国家与仓库地址不一致。请重新选择");
        return;
      }

      let data = {
        Id: this.id || 0,
        Flag: flag,
        Name: this.name,
        ImageList: this.imageList.map((v) => v.url),
        Category: this.category,
        Country: this.country,
        WarehouseType: this.warehouseType,

        Province: address?.province,
        City: address?.city,
        ZipCode: address?.zipCode,
        Contact: address?.contact,
        Phone: address?.phone,
        Address1: address?.address1,
        Address2: address?.address2,

        Detail: this.detail.Detail,
        Stock: this.stock,
        MinOrder: this.minOrder,
        Price: this.price,
        OriginalPrice: this.originalPrice,
        Currency: this.currency,
        Url: this.url,
        IsCompetitorUrl: this.isCompetitorUrl,
        IsSellPrice: this.isSellPrice,
      };

      let result = await Service.editProduct(data);
      Toast.success("保存成功");

      this.id = result.Id;

      this.$emit("submit", flag);
    },

    syncWarehouseAddress() {
      if (!this.warehouseAddress || !this.warehouseAddress.province) {
        return;
      }

      let target = this.warehouseList.find((v) => this.warehouseAddressEquals(v.item, this.warehouseAddress));
      if (target == null) {
        target = {
          name: Util.getAddressDesc(this.warehouseAddress.countryDesc, this.warehouseAddress.province, this.warehouseAddress.city, this.warehouseAddress.zipCode),
          value: 0,
          item: this.warehouseAddress,
        };

        this.warehouseList.push(target);
      }

      this.warehouse = target.value;
      this.warehouseDesc = target.name;
    },

    warehouseAddressEquals(warehouseItem, warehouseAddress) {
      return warehouseItem.country == warehouseAddress.country && warehouseItem.province == warehouseAddress.province && warehouseItem.city == warehouseAddress.city && warehouseItem.zipCode == warehouseAddress.zipCode && warehouseItem.contact == warehouseAddress.contact && warehouseItem.phone == warehouseAddress.phone && warehouseItem.address1 == warehouseAddress.address1 && warehouseItem.address2 == warehouseAddress.address2;
    },

    afterRead(file) {
      let files = [];
      if (Array.isArray(file)) {
        files = file;
      } else {
        files.push(file);
      }

      files.forEach(async (item) => {
        try {
          item.status = "uploading";

          let data = {
            FileName: item.file.name,
            File: item.file,
          };
          let result = await Service.uploadImage(data);

          if (this.imageList.some((v) => v.url == result.Url)) {
            let i = this.imageList.indexOf(item);
            if (i > -1) {
              this.imageList.splice(i, 1);
              Toast("已过滤重复图片");
            }
          } else {
            item.status = "done";
            item.url = result.Url;
          }
        } catch (error) {
          Toast("文件上传失败：" + error);

          let i = this.imageList.indexOf(item);
          if (i > -1) {
            this.imageList.splice(i, 1);
          }
        }
      });
    },

    onOversize() {
      Toast("文件大小不能超过 5M");
    },

    addAddress() {
      var dialog = Util.createComponent(
        EditAddress,
        {
          forward: () => {
            this.$router.push(this.addAddressUrl);
          },
          back: () => {
            this.$router.back();
          },
          refresh: () => {
            this.refreshAddressList();
          },
        },
        document.querySelector("#app")
      );
      dialog.open();
    },

    onCountrySelect(item) {
      // 不能提示，否则无法更改
      // if (this.warehouseType == 2) {
      //   let address = this.warehouseList.find((v) => v.value == this.warehouse)?.item;
      //   if (address && item.value != address.country) {
      //     Toast.fail("仓库所在国家与仓库地址不一致。请重新选择");
      //     return;
      //   }
      // }

      this.countryDesc = item.name;
      this.country = item.value;

      let defaultCurrency = this.currencyList.find((v) => v.value == item.currency);
      this.currency = defaultCurrency?.value;
      this.currencyDesc = defaultCurrency?.name;
    },
    onWarehouseSelect(data) {
      if (this.country && this.country != data.item.country) {
        Toast.fail("仓库所在国家与仓库地址不一致。请重新选择");
        return;
      }
      this.warehouse = data.value;
      this.warehouseDesc = data.name;
      this.warehouseAddress = null;

      if (!this.country) {
        this.country = data.item.country;
        this.countryDesc = data.item.countryDesc;

        if (!this.currency) {
          let country = this.countryList.find((v) => v.value == data.item.country);
          let defaultCurrency = this.currencyList.find((v) => v.value == country.currency);

          this.currency = defaultCurrency?.value;
          this.currencyDesc = defaultCurrency?.name;
        }
      }
    },
  },

  created() {
    this.refresh();
  },

  async mounted() {
    let subscribeResult = await Service.checkSubscribe();
    if (subscribeResult.Subscribe) {
      let checkAddResult = await Service.checkAddProduct();
      if (!checkAddResult.CanAddProduct) {
        var dialog = Util.createComponent(
          EditContact,
          {
            forward: () => {
              // this.$router.push('/')
            },
            back: () => {
              // this.$router.back()
            },
            title: "完善用户信息",
            hint: "为方便后续沟通，请补充联系方式",
            showBack: false,
          },
          document.querySelector("#app")
        );
        dialog.open();
      }
    } else {
      this.$router.push("/login");
    }
  },
};
</script>
<style>
.van-toast {
  z-index: 2999999 !important;
}
.tox-silver-sink {
  z-index: 2999991 !important;
}
</style>
<style lang="scss" scoped>
.form {
  background: #fff;
  color: #2f2f2f;
  font-size: 12rem;

  .title {
    font-size: 14rem;
    font-weight: 500;
    color: #2f2f2f;
    background: #fafafa;
    border-radius: 8rem 8rem 0 0;
    border-bottom: 1rem solid #f0f0f0;
    padding: 8rem 0 8rem 12rem;
    margin: 40rem 12rem 10rem 12rem;

    &:first-child {
      margin: 10rem 12rem;
    }
  }

  .field-set {
    margin: 0 20rem;
  }

  .label {
    margin: 20rem auto 10rem auto;
    color: #2f2f2f;
    font-size: 13rem;
    font-weight: 400;

    &.required::before {
      content: "*";
      color: #ee0a24;
      margin-right: 3rem;
    }
  }

  .field {
    padding: 4rem 10rem;
    border: 1rem solid #ccc;
    border-radius: 4rem;
    flex: 1;

    :deep(.van-cell__right-icon) {
      color: #ccc;
      transform: rotate(90deg);
    }
  }

  .field-line {
    display: flex;

    .van-button {
      height: auto;
      padding: 0 8rem;
      margin-left: 5rem;
      color: #c8c9cc;
      background: #ffffff;
      border: 1rem solid #cccccc;
      border-radius: 4rem;
    }
  }

  :deep(.van-uploader__preview) {
    width: 88rem;
    height: 88rem;
    background: #fafafa;
    border: 1rem dashed #cccccc;
    border-radius: 4rem;
  }

  :deep(.van-uploader__input-wrapper) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88rem;
    height: 88rem;
    background: #fafafa;
    border: 1rem dashed #cccccc;
    border-radius: 4rem;
    color: #999999;

    .uploader-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .uploader-text {
        margin-top: 4rem;
      }
    }
  }

  .hint {
    font-size: 13rem;
    font-weight: 400;
    color: #999999;
    margin: 5rem 0 0 0;
  }

  .hint-checkbox {
    margin-top: 7rem;

    :deep(.van-checkbox__label) {
      font-size: 13rem;
      font-weight: 400;
      color: #2f2f2f;
    }
  }

  :deep(.van-field__control) {
    font-size: 14rem;
    font-weight: 400;
    color: #2f2f2f;
  }

  :deep(.van-cell) {
    &::after {
      content: none;
    }
  }

  .button-wrap {
    margin: 50rem 0 30rem 0;
    text-align: center;

    :deep(.van-button) {
      border-radius: 2rem;
      font-size: 14rem;
      padding: 0 15rem;
      height: 30rem;
      margin-right: 10rem;
    }

    :deep(.van-button--info) {
      background: #009dff;
    }

    :deep(.van-button--plain) {
      color: #009dff;
      background: #fff;
    }
  }
}
</style>
