import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import { Lazyload } from 'vant'
import { Icon } from 'vant';

import 'vant/lib/index.css'

import './assets/css/base.scss';
import './assets/font/iconfont.css'

Vue.use(Vant);
Vue.use(Lazyload);
Vue.use(Icon);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
