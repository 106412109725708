<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="tab-wrapper">
      <van-tabs v-model="type" @click="onClick(true)" color="#009dff" line-width="70rem">
        <van-tab :name="1">
          <template #title>
            我买的 <span v-if="total1 || total1 === 0">({{ total1 }})</span></template>
        </van-tab>
        <van-tab :name="2">
          <template #title>
            我卖的<span v-if="total2 || total2 === 0">({{ total2 }})</span>
          </template>
        </van-tab>
        <div class="label">
          <van-tabs v-model="flag" @click="onClick(false)" color="#009dff" line-width="60rem">
            <van-tab :name="1">
              <template #title>
                待处理 <span v-if="Flag1Count || Flag1Count === 0">({{ Flag1Count }})</span>
              </template>
            </van-tab>
            <van-tab :name="2">
              <template #title>已拒绝<span v-if="Flag2Count || Flag2Count === 0">({{ Flag2Count }})</span></template>
            </van-tab>

            <van-tab :name="3">
              <template #title>
                已接受 <span v-if="Flag3Count || Flag3Count === 0">({{ Flag3Count }})</span>
              </template>
            </van-tab>
            <van-tab :name="-1">
              <template #title>
                全部 <span v-if="FlagAllCount || FlagAllCount === 0">({{ FlagAllCount }})</span>
              </template>
            </van-tab>
          </van-tabs>
        </div>
      </van-tabs>
    </div>

    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <ul>
          <li v-for="item in listData" :key="item.Id">
            <div class="list-item">
              <div class="list-state">
                <div>
                  <i> {{ item.Id }}</i>
                  <van-icon name="arrow" color="#d5d2d2" />
                </div>
                <div class="order-time">{{ item.CreateTime }}</div>
                <span style="color: rgb(202, 205, 208)">|</span>
                <div class="desc">
                  {{ item.FlagDesc }} <span v-if="item.Flag == 1 || item.Flag == 4"> <van-count-down :time="item.LeftSeconds * 1000"> </van-count-down></span>
                </div>
              </div>
              <div class="list-data">
                <van-image :src="item.ProductImage" lazy-load />
                <div class="state">
                  <div class="van-multi-ellipsis--l2 title">
                    {{ item.ProductName }}
                  </div>
                  <div class="price">
                    {{ item.ProductPriceDesc }}
                    <div>x{{ item.ProductNum }}</div>
                    <div>{{ item.ProductAmountDesc }}</div>
                  </div>
                </div>
              </div>
              <div class="remark" v-if="(item.Flag == 1 && type == 1) || item.Flag == 2 || (item.Flag == 4 && type == 2)">{{ getRemark(item.Flag, item.Remark) }}</div>

              <div class="list-btn" v-if="(type == 2 && item.Flag == 1) || (type == 1 && item.Flag == 4)">
                <van-button round type="info" @click="refuse(item, type)">拒绝</van-button>
                <van-button class="edit" round type="info" @click="agree(item.Id)">接受</van-button>
              </div>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
  </div>
</template>
<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";
import RefuseDialog from "./RefuseDialog.vue";
import Confirm from "@/components/Confirm";
import PageTop from "@/components/PageTop";

export default {
  name: "BiddingList",
  components: { PageTop },

  data() {
    return {
      pageSize: 20,
      pageIndex: 1,
      type: 1,

      total: "",
      total1: "",
      total2: "",

      finished: false,
      loading: false,

      Flag1Count: "",
      Flag2Count: "",
      Flag3Count: "",
      FlagAllCount: "",

      flag: "",
      listData: [],
    };
  },
  computed: {},
  watch: {},

  methods: {
    async getList(reset, showLoading) {
      if (reset) {
        this.finished = false;
        this.pageIndex = 1;
        this.listData = [];
      }

      let params = {
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        Type: this.type,
        Flag: this.flag,
      };
      this.loading = true;
      let data = await Service.getBiddingList(params, showLoading);
      if (reset) {
        this.pageindex = 1;
        this.listData = [];
      }
      this.loading = false;

      this.total = data.Total;
      this.total1 = data.Total1;
      this.total2 = data.Total2;

      this.Flag1Count = data.Flag1Count;
      this.Flag2Count = data.Flag2Count;
      this.Flag3Count = data.Flag3Count;
      this.FlagAllCount = data.FlagAllCount;

      this.pageIndex++;

      data.Rows.forEach((item) => {
        this.listData.push(item);
      });

      if (this.listData.length >= this.total) {
        this.finished = true;
      }
    },

    getRemark(flag, remark) {
      if (flag == 1) {
        return "请等待卖家处理";
      } else if (flag == 2) {
        return remark;
      } else if (flag == 4) {
        return "请等待买家处理";
      } else {
        return "";
      }
    },

    onLoad() {
      this.getList(false, false);
    },
    onClick(resetFlag) {
      if (resetFlag) {
        this.flag = 1;
      }
      this.getList(true);
    },

    refuse(item, type) {
      var dialog = Util.createComponent(
        RefuseDialog,
        {
          refresh: () => {
            this.getList(true, false);
          },
        },
        document.querySelector("#app")
      );
      dialog.open(item, type);
    },

    agree(id) {
      let dialog = Util.createComponent(
        Confirm,
        {
          title: "接受竞价",
          message: "此操作将接受竞价，是否继续？",
          confirmButtonText: "接受竞价",
          confirm: async () => {
            await Service.setBiddingFlag({
              Id: id,
              Flag: 3,
              Remark: "",
            });
            Toast.success("已接受竞价");
            this.getList(true, false);
          },
        },
        document.querySelector("#app")
      );
      dialog.open();
    },
    show(eye) {
      if (eye) {
        return (this.eye = false);
      } else {
        return (this.eye = true);
      }
    },

    copyText(text) {
      Util.copyText(text);
    },
  },
  mounted() {
    if (this.$route.path !== "/biddingList") {
      this.$router.replace("/biddingList");
    }
    let type = this.$route.query.type;
    if (type == 1 || type == 2) {
      this.type = Number(type);
    }
    let flag = this.$route.query.flag;
    if ([-1, 1, 2, 3].some((v) => v == flag)) {
      this.flag = Number(flag);
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;

  .header {
    padding-bottom: 0;
  }

  .content {
    overflow-y: auto;
    padding: 0;
    padding-top: 15rem;
  }

  .van-list {
    padding: 0 10rem;
  }
}

.tab-wrapper {
  width: auto;

  background-color: rgb(255, 255, 255);

  .label {
    padding-top: 10rem;
    height: 50rem;

    ::v-deep {
      .van-tab {
        display: inline-block;
        text-align: center;
        font-size: 14rem;
      }

      .van-tabs__wrap {
        height: 30rem;
      }
    }
  }

  ::v-deep {
    .van-tab {
      font-size: 15rem;
      line-height: 20rem;
      padding: 0 4rem;
    }

    .van-tabs__wrap {
      height: 43rem;
    }

    .van-tabs__nav--line {
      padding-bottom: 10rem;
    }

    .van-tabs__line {
      height: 3rem;
    }
  }
}

.sub-nav {
  position: relative;

  .colon {
    display: inline-block;
    margin: 0 4px;
  }

  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  ::v-deep {
    .batch {
      position: absolute;
      top: 0;
      right: 0;
      height: 35rem;
      border: 0;
      background-color: #f5f7fa;
      color: black;
      font-size: 13rem;
      line-height: 22rem;
    }
  }

  ::v-deep {
    .van-dropdown-menu {
      background-color: #f5f7fa;
    }

    .van-dropdown-menu__bar {
      background-color: #f5f7fa;
      width: 260rem;
      height: 35rem;
      color: aliceblue;
      box-shadow: none;
      left: -11rem;
    }

    .van-dropdown-menu__title {
      color: #32323385;
      font-size: 12rem;
      line-height: 22rem;
      padding: 0 8rem;
    }

    .van-dropdown-item__option--active {
      color: blue;
    }

    .van-cell__value {
      flex: 0 1 auto;
    }
  }
}

ul {
  li {
    ::v-deep {
      .van-checkbox__icon {
        display: none;
      }

      .van-checkbox__icon--checked .van-icon {
        background-color: #009dff;
        border-color: #009dff;
      }

      .van-checkbox__label {
        margin-left: 0;
        flex: 1;
        overflow: hidden;
      }

      .batch {
        .van-checkbox__icon {
          display: block;
          margin-right: 10rem;
        }
      }
    }

    .list-item {
      flex: 1;
      background-color: white;
      border-radius: 10rem;
      margin-bottom: 15rem;

      .remark {
        text-align: right;
        padding-bottom: 15rem;
        padding-right: 20rem;
      }

      .list-state {
        display: flex;
        align-items: baseline;
        padding: 10rem 0 0 10rem;
        font-size: 13rem;

        :first-child {
          flex: 1;
        }

        .order-time {
          font-size: 13rem;
          font-weight: 500;
          color: #88898a;
          margin-right: 5rem;
        }

        .desc {
          margin-left: 5rem;
          margin-right: 10rem;
          color: #db7b21;
          font-weight: 500;
          font-size: 13rem;

          .van-count-down {
            display: inline-block;
            margin-left: 0;
            color: #db7b21;
            font-weight: 500;
            font-size: 13rem;
          }
        }
      }

      .order-information {
        background-color: #f5f7fa;
        border-radius: 10rem;
        height: 100rem;
        margin: 15rem 20rem 20rem 10rem;
        font-size: 13rem;

        .list-address {
          display: flex;
          font-weight: 600;

          :last-child {
            display: flex;
            margin-top: 3rem;

            ::v-deep {
              .van-icon {
                margin-right: 3rem;
              }
            }
          }
        }

        .address {
          display: flex;

          ::v-deep {
            .van-icon {
              margin-top: 8rem;
            }
          }
        }

        .order-no {
          padding-top: 10px;
          color: #88898a;

          ::v-deep {
            .van-icon {
              color: black;
              font-weight: 500;
            }
          }

          span {
            color: black;
            font-weight: 500;
          }
        }

        ::v-deep {
          .van-icon {
            margin-right: 10rem;
            margin-left: 7rem;
          }
        }
      }

      .total {
        text-align: end;
        margin-right: 22rem;

        span {
          color: #88898a;
        }
      }

      .list-data {
        display: flex;
        padding: 20rem 10rem 10rem 10rem;

        .van-image {
          flex-shrink: 0;
          width: 100rem;
          height: 100rem;
          margin: 0 10rem 0 0;
        }

        .state {
          flex: 1;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          .title {
            font-size: 13rem;
            font-weight: 500;
            line-height: 20rem;
            height: 40rem;
          }

          .remark {
            font-size: 13rem;
          }

          .amount {
            margin-top: 5rem;
            color: darkgray;
            font-size: 13rem;

            span {
              padding: 3rem;
            }
          }

          .price {
            height: 60rem;
            color: black;
            font-size: 15rem;
            font-weight: 500;
            margin-right: 10rem;
            text-align: right;

            div {
              padding-top: 10rem;
              text-align: end;
              font-weight: 500;
            }

            .line {
              font-size: 12rem;
              color: #999;
              text-decoration: line-through;
              margin-left: 8rem;
            }
          }
        }
      }

      .list-btn {
        padding: 10rem 15rem 10rem 110rem;
        text-align: right;

        ::v-deep {
          .van-button {
            font-size: 12rem;
            height: 30rem;
            margin-left: 15rem;
          }

          .van-button--normal {
            padding: 0 15rem;
          }

          .van-button {
            background-color: #f5f7fa;
            color: black;
            border: 0;
          }

          .van-button__content {
            background-color: #f5f7fa;
          }
        }

        .edit {
          color: #009dff;
          background-color: #e8edff;

          :deep(.van-button__content) {
            background-color: #e8edff;
          }
        }
      }
    }
  }
}

.end {
  background-color: white;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60rem;
  margin: 0 10rem;
  font-size: 14rem;

  :deep(.van-button) {
    height: 35rem;
  }

  .end-del {
    margin-left: 15rem;
  }
}
</style>
