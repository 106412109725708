<template>
  <van-dialog v-model="show" title="拒绝原因" show-cancel-button confirm-button-color="#009dff" :before-close="beforeClose">
    <div class="refuse">
      <van-radio-group v-model="value">
        <van-radio :name="1"> {{ type == 2 ? "价格太低" : "价格太高" }}</van-radio>
        <van-cell-group v-if="value == 1">
          <van-field v-model="price" required type="number" :label-width="type == 2 ? '75rem' : '65rem'" :name="type == 2 ? '可接受价格' : '您的报价'" :label="type == 2 ? '可接受价格' : '您的报价'" placeholder="请输入价格">
            <template #extra>
              <span class="currency">{{ item.ProductCurrencyDesc }}</span>
            </template>
          </van-field>
        </van-cell-group>
        <van-radio :name="2"> {{ remark[type][2] }}</van-radio>
        <van-radio :name="3" v-if="type == 2">{{ remark[type][3] }} </van-radio>
      </van-radio-group>
    </div>
  </van-dialog>
</template>

<script>
import { Toast } from "vant";
import Service from "@/utils/service";
export default {
  components: {},
  props: ["refresh"],
  data() {
    return {
      show: false,
      item: {},
      type: "1",
      price: "",
      num: "",
      value: "",
      remark: { 1: { 2: "终止议价" }, 2: { 2: "商品售罄", 3: "终止议价" } },
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(item, type) {
      this.item = item;
      this.type = type;
      this.show = true;
    },

    async beforeClose(action, done) {
      if (action == "confirm") {
        if (this.value > 0) {
          let data = {
            Id: this.item.Id,
            Flag: this.value == 1 ? (this.type == 1 ? 1 : 4) : 2,
            Price: this.price,
            Remark: this.remark[this.type][this.value] ?? "",
          };
          if (this.value == 1) {
            if (this.price <= 0) {
              Toast("请输入正确报价");
              done(false);
              return;
            }
          }
          try {
            await Service.setBiddingFlag(data);
          } catch (error) {
            done(false);
            return;
          }

          Toast.success("操作成功");

          this.refresh();
          done();
        } else {
          Toast("请选择拒绝原因");
          done(false);
        }
      } else {
        done();
      }
    },

    created() { },
    mounted() { },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-dialog {
    width: 100%;

    .van-dialog__content {
      width: 250rem;
    }

    .van-hairline--top {
      border: 20rem solid red;
    }

    [class*="van-hairline"]::after {
      border: none;
    }
  }
}

.refuse {
  display: flex;
  justify-content: center;

  ::v-deep {
    .van-radio-group {
      min-width: 220rem;
      margin-top: 10rem;
      width: auto;

      .van-radio {
        margin: 20rem 0 20rem 0;
      }
    }

    .van-cell {
      border-bottom: 1rem solid #ebedf0;
      font-size: 14rem;
    }

    [class*="van-hairline"]::after {
      border: none;
    }

    .van-cell:after {
      border: none;
    }

    .van-cell-group {
      .van-cell {
        margin-bottom: 10rem;
        padding-right: 0;

        .van-cell__value {
          width: 100rem;
        }
      }

      .currency {
        color: red;
      }
    }
  }
}
</style>
