<!--  -->
<template>
  <van-dialog v-if="show" v-model="show" class="full-screen" :closeOnPopstate="false">
    <van-nav-bar title="填写订单" left-text="返回" left-arrow @click-left="close()" />
    <div class="page">
      <div class="content">
        <ul>
          <li>
            <AddressCard v-if="orderInfo.ProductWarehouseType == 1" @click="onAdd" :address="address"></AddressCard>
            <div v-else>
              <span class="title"> 取货信息</span>
              <div class="seller-address">{{ orderInfo.CountryDesc }} {{ orderInfo.Province }} {{ orderInfo.City }}</div>
            </div>
            <div class="product-information">
              <div class="title">
                商品信息
                <div class="order-time total">{{ orderInfo.CreateTime }} 拍下</div>
              </div>

              <div class="list-data">
                <van-image :src="orderInfo.ProductImage" />
                <div class="state">
                  <div class="van-multi-ellipsis--l2 title">
                    {{ orderInfo.ProductName }}
                  </div>
                  <div class="price">
                    <span>{{ orderInfo.ProductPriceDesc }}</span>
                    <div>x{{ orderInfo.ProductNum }}</div>
                    <div>{{ orderInfo.ProductAmountDesc }}</div>
                  </div>
                </div>
              </div>
              <div class="total">
                <span>共</span>
                {{ orderInfo.ProductNum }}
                <span>件</span>
                &nbsp;
                <span>合计:</span>
                {{ orderInfo.AmountDesc }}
                <span>(含服务费:</span>
                {{ orderInfo.ServiceFeeDesc }}
                <span>)</span>
              </div>
              <div v-if="orderInfo.ProductCurrency != 1" class="total">
                <span>汇率</span>
                {{ orderInfo.ExchangeRate }}

                &nbsp;
                <span>合计:</span>
                {{ orderInfo.AmountCNYDesc }}
                <span>(含服务费:</span>
                {{ orderInfo.ServiceFeeCNYDesc }}
                <span>)</span>
              </div>
            </div>

            <div class="uploader">
              <van-radio-group v-model="payType" click="">
                <van-radio :name="1">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-weixinzhifu"></use>
                  </svg>
                  微信支付(推荐)
                </van-radio>
                <van-radio name="-2">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-zhifubao"></use>
                  </svg>
                  支付宝(推荐)
                </van-radio>
                <div v-if="payType == -2" class="bank">
                  <div><span> 收款账号：13537648506</span></div>
                  <div><span> 收款人：李翠娥 </span></div>
                  <div class="bank-tip">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>
                <van-radio :name="3">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-yinhangqia"></use>
                  </svg>
                  人民币对公
                </van-radio>
                <div v-if="payType == 3" class="bank">
                  <div>
                    <span>收款户名</span>
                    :
                    <span>深圳市飞同樊祥信息技术有限公司</span>
                  </div>
                  <div>
                    <span>收款银行</span>
                    :
                    <span>招行深圳新安支行</span>
                  </div>
                  <div>
                    <span>收款账号</span>
                    :
                    <span>755960026710808</span>
                  </div>
                  <div class="bank-tip">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>
                <van-radio :name="4">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-ouyuan"></use>
                  </svg>
                  欧元转账
                </van-radio>
                <div v-if="payType == 4" class="bank">
                  <div><span> IBAN：LU344080000026834178</span></div>
                  <div>
                    <span> Account Name：Shenzhen Fei Tong Fan Xiang information Technology Co.,LTD <br />* If the account name is too long, you can continue filling it into the address field. </span>
                  </div>
                  <div><span> Bank Name：BANKING CIRCLE S.A. </span></div>
                  <div><span> Bank Address：2 Boulevard de la Foire L-1528 Luxembourg </span></div>
                  <div><span> Country/Region：LUXEMBOURG </span></div>
                  <div><span> SWIFT/BIC Code：BCIRLULL </span></div>
                  <div><span> Type of Account：Business Account </span></div>
                  <div><span> Remark：For the payment of goods, please make a SEPA Inst/SEPA SCT Payment in EUR </span></div>
                  <div class="bank-tip">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>
                <van-radio :name="5">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-meiyuan"></use>
                  </svg>
                  美元转账
                </van-radio>
                <div v-if="payType == 5" class="bank">
                  <div><span> SWIFT/BIC Code：DHBKHKHH (DHBKHKHHXXX * If 11 characters are required)</span></div>
                  <div><span> Account Number：79969000323792 </span></div>
                  <div>
                    <span>
                      Account Name：Shenzhen Fei Tong Fan Xiang information Technology Co.,LTD<br />
                      * If the account name is too long, you can continue filling it into the address field.
                    </span>
                  </div>
                  <div><span> Bank Name：DBS Bank (Hong Kong) Limited </span></div>
                  <div><span> Bank Address：11th Floor, The Center, 99 Queen's Road Central, Central, Hong Kong </span></div>
                  <div><span> Country/Region：Hong Kong </span></div>
                  <div><span> Type of Account：Business Account </span></div>
                  <div><span> Bank Code：016 </span></div>
                  <div><span> Branch Code：478* If paying from Hong Kong banks </span></div>
                  <div><span> Remark：For the payment of goods, please make a SWIFT(T/T) or CHATS(HK Local) Payment </span></div>
                  <div class="bank-tip">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>
                <van-radio :name="6">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-yingbang"></use>
                  </svg>
                  英镑转账
                </van-radio>
                <div v-if="payType == 6" class="bank">
                  <div><span> Account Number：11016498</span></div>
                  <div><span> Sort Code：234238</span></div>

                  <div>
                    <span>
                      Account Name：Shenzhen Fei Tong Fan Xiang information Technology Co.,LTD <br />
                      * If the account name is too long, you can continue filling it into the address field.
                    </span>
                  </div>
                  <div><span> Bank Name：Deutsche Bank AG, London </span></div>
                  <div><span> Bank Address：1 Great Winchester Street EC2N 2DB LONDON UNITED KINGDOM </span></div>
                  <div><span> Country/Region：United Kingdom </span></div>
                  <div><span> SWIFT/BIC Code：DEUTGB2L </span></div>
                  <div><span> Type of Account：Business Account </span></div>
                  <div><span> Remark：For the payment of goods, please make a BACS/CHAPS/FPS Payment in GBP </span></div>
                  <div class="bank-tip">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>
                <van-radio :name="-3">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#iconfont-ustd"></use>
                  </svg>
                  USTD转账
                </van-radio>
                <div v-if="payType == -3" class="bank">
                  <div style="text-align: center">TFRb4zektr4o9wCZsStA2MtMhNsRUcK2TG</div>
                  <QRcode class="ustd" :url="ustd"></QRcode>
                  <div class="bank-tip" style="text-align: center">
                    <span>请在支付完成后选择下一步，上传支付凭证</span>
                  </div>
                </div>

                <!-- <div v-if="payType == 3">
                  <van-image class="wechat-pay" :src="require('@/assets/images/pay.jpg')" fit="contain" />
                </div> -->
              </van-radio-group>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="edit">
      <div class="price">
        {{ payType == 1 || payType == -2 ? orderInfo.AmountCNYDesc : payType == 3 ? orderInfo.AmountChargeCNYDesc : payType == 4 ? orderInfo.AmountChargeEuroDesc : payType == 5 ? orderInfo.AmountChargeDollarDesc : payType == 6 ? orderInfo.AmountChargeGBPDesc : payType == -3 ? orderInfo.AmountChargeUSDTDesc : "" }} <br />
        <span style="font-size: 13rem; font-style: italic; color: #999">{{ payType == 1 || payType == -2 ? "(免支付手续费Free)" : payType == 3 || payType == 4 || payType == 5 || payType == 6 ? "(含3%支付手续费)" : payType == -3 ? "(含10%支付手续费)" : "" }}</span>
      </div>
      <van-button round type="danger" color="#009dff" native-type="submit" @click="submit"> {{ payType == 1 ? "支付" : "下一步" }} </van-button>
    </div>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";
import AddressList from "./AddressList.vue";
import AddressCard from "@/views/Address/AddressCard";
import PayOrderImage from "@/views/Order/PayOrderImage";
import Style from "@/assets/font/iconfont";
import QRcode from "@/components/QrCode.vue";

export default {
  name: "PayOrder",
  components: { AddressCard, QRcode },
  props: ["forward", "back", "refresh", "path"],

  data() {
    return {
      show: false,
      Id: "",
      payType: 1,
      shouldClose: false,
      value: 1,
      ustd: "TFRb4zektr4o9wCZsStA2MtMhNsRUcK2TG",

      orderInfo: {
        ProductName: "",
        AmountDesc: "",
        CreateTime: "",
        ProductNum: "",
        ProductPriceDesc: "",
        ServiceFeeDesc: "",
        ProductImage: "",
        ProductAmountDesc: "",
        ProductWarehouseType: "",
      },
      imageList: [],
      address: null,
      balck: {
        invoke: (data) => {
          data();
        },
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(id) {
      this.Id = id;
      this.load();
      this.show = true;
      this.forward?.();
    },
    async load() {
      let params = {
        id: this.Id,
      };
      let data = await Service.getOrderDetail(params);
      if (data.Contact) {
        this.address = data;
      }
      this.orderInfo = data;
    },

    close() {
      this.back?.();
    },
    copyText(text) {
      Util.copyText(text);
    },

    onAdd() {
      var dialog = Util.createComponent(
        AddressList,
        {
          forward: () => {
            this.$router.push(`${this.path}/addressList`);
          },
          back: () => {
            this.$router.back();
          },
          refresh: () => {
            this.refreshAddressList();
          },
          submit: (address) => {
            this.address = address;
          },
          path: `${this.path}/addressList`,
        },
        document.querySelector("#app")
      );
      dialog.open();
    },
    async submit() {
      if (this.address || this.orderInfo.ProductWarehouseType == 2) {
        if (this.payType == -2 || this.payType == -3 || this.payType == 3 || this.payType == 4 || this.payType == 5 || this.payType == 6) {
          var dialog = Util.createComponent(
            PayOrderImage,
            {
              forward: () => {
                this.$router.push(`${this.path}/payOrderImage`);
              },
              back: (shouldClose) => {
                this.shouldClose = shouldClose;
                this.$router.back();
              },
              refresh: () => {
                this.load();
              },
            },
            document.querySelector("#app")
          );
          dialog.open(this.Id, this.address);
        } else if (this.payType == 1) {
          let data = {
            Id: this.orderInfo.Id,
            Contact: this.address?.Contact,
            Phone: this.address?.Phone,
            Country: this.address?.Country,
            Province: this.address?.Province,
            City: this.address?.City,
            ZipCode: this.address?.ZipCode,
            Address1: this.address?.Address1,
            Address2: this.address?.Address2,
            PayType: 1,
            WxPayTradeType: "JSAPI",
          };

          let result = await Service.payOrder(data);
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener("WeixinJSBridgeReady", this.onBridgeReady(result.JSAPIObj), false);
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady(result.JSAPIObj));
              document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady(result.JSAPIObj));
            }
          } else {
            this.onBridgeReady(result.JSAPIObj);
          }
        } else {
          Toast.fail("请选择支付方式");
          return;
        }
      } else {
        Toast.fail("请选择收货地址");
        return;
      }
    },
    onBridgeReady(data) {
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: data.appId, //公众号ID，由商户传入
          timeStamp: data.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: data.nonceStr, //随机串
          package: data.package,
          signType: data.signType, //微信签名方式：
          paySign: data.paySign, //微信签名
        },
        (res) => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.refresh();
            this.$router.replace("/orderList?flag=2");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      );
    },
  },

  created() {},
  mounted() {
    let watchRoute = this.$watch("$route", () => {
      if (this.shouldClose || !this.$route.path.startsWith(this.path)) {
        watchRoute();
        if (this.shouldClose) {
          this.back();
          this.refresh();
        }
        this.show = false;
      }
    });
  },
};
</script>
<style lang="scss" scoped Style>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 20rem;
}

.full-screen {
  ::v-deep {
    .van-dialog__content {
      height: 100%;
    }
  }
}

.page {
  height: 100%;
  overflow: hidden;

  .header {
    padding-bottom: 0;
  }

  .content {
    background-color: white;

    overflow-y: auto;
    padding: 0;

    .seller-address {
      padding: 16rem;
      background-color: #fff;
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        height: 2rem;
        background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
        background-size: 80rem;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }

  .van-list {
    padding: 0 10rem;
  }
}

ul {
  li {
    background-color: white;
    margin-bottom: 10rem;
    border-radius: 10rem;
    padding-left: 10rem;
    padding-right: 10rem;
    padding-top: 5rem;

    .title {
      font-size: 13rem;

      display: flex;
      justify-content: space-between;

      div {
        display: flex;
        justify-content: space-between;
      }
    }

    .product-information {
      margin-top: 10rem;

      .list-data {
        display: flex;
        padding: 20rem 10rem 10rem 10rem;
        justify-content: space-between;

        .van-image {
          flex-shrink: 0;
          width: 100rem;
          height: 100rem;
          margin: 0 10rem 0 0;
        }

        .state {
          flex: 1;
          display: flex;
          justify-content: space-between;
          overflow: hidden;

          .title {
            font-size: 13rem;
            font-weight: bold;
            line-height: 20rem;
            height: 40rem;
          }

          .price {
            display: flex;
            flex-direction: column;
            height: 60rem;
            color: black;
            font-size: 15rem;
            font-weight: bold;
            text-align: end;

            ::v-deep {
              .van-stepper {
                padding-top: 0rem;
              }

              div {
                color: #ee0a24;
                padding-top: 10rem;
                font-weight: 500;
              }
            }
          }
        }

        .freight {
          font-size: 15rem;

          text-align: end;
          padding: 0 0 20rem 0;
        }

        .freight + div {
          color: #88898a;
          font-size: 15rem;
          text-align: end;
          padding: 0 0 20rem 0;

          :last-child {
            font-weight: 600;
            color: black;
          }
        }
      }

      .total {
        text-align: end;
        margin-right: 10rem;
        font-size: 13rem;
        color: #88898a;

        span {
          color: #88898a;
        }
      }
    }

    .uploader {
      display: flex;
      flex-direction: column;
      margin-top: 20rem;

      .van-radio-group {
        font-size: 15rem;

        .bank {
          display: flex;
          font-size: 13rem;
          flex-direction: column;
          color: #88898a;
          margin: 0 0 20rem 7rem;

          div {
            margin: 10rem 0 10rem 0;
          }

          .bank-tip {
            color: #ee0a24;
          }
        }

        .van-radio {
          display: block;
          position: relative;
          margin: 20rem 0;

          ::v-deep {
            .van-radio__icon {
              position: absolute;
              right: 10rem;
            }
          }
        }

        .wechat-pay {
          width: 100%;
        }
      }

      ::v-deep {
        .van-uploader {
          margin-top: 10rem;
        }
      }
    }
  }
}

.list-btn {
  width: 100%;
  text-align: right;

  ::v-deep {
    .van-button {
      font-size: 12rem;
      height: 30rem;
    }

    .van-button--normal {
      padding: 0 15rem;
    }

    .van-button {
      background-color: #f5f7fa;
      color: black;
      border: 0;
    }

    .van-button__content {
      background-color: #f5f7fa;
    }
  }
}

.edit {
  display: flex;
  flex-direction: row;
  margin: 10rem;
  color: #009dff;
  align-items: center;
  justify-content: space-between;

  .price {
    font-size: 20rem;
    color: #ee0a24;
  }

  .van-button {
    min-width: 0;
    height: 40rem;
  }
}
</style>
