<template>
  <div id="app">
    <router-view id="routerView" v-if="isShow" />
    <svg class="icon-contact" aria-hidden="true" @click="showContact">
      <use xlink:href="#iconfont-wechat"></use>
    </svg>
  </div>
</template>

<script>
import { Toast } from "vant";
import Service from "@/utils/service";
import Util from "@/utils/util";
import "@/assets/font/iconfont";
import AfterSales from "@/components/AfterSales";
import ProductDetail from "@/views/Home/ProductDetail.vue";

export default {
  name: "App",
  components: {},
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    queryCode() {
      let appId = "wx021c4f6ff8e1dbaf";
      let redirectUrl = encodeURIComponent(`${window.location.protocol}//${window.location.host}/checkAuthCode?redirect=${this.$route.path}`);

      // let redirectUrl = encodeURIComponent(`https://www.qingcang1.com/checkAuthCode?redirect=${this.$route.path}`);

      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=123456#wechat_redirect`;
      window.location.replace(url);
    },

    async checkAuthCode() {
      let code = this.$route.query.code;
      await Service.checkAuthCode({
        code,
      });
    },

    showContact() {
      let dialog = Util.createComponent(
        AfterSales,
        {
          title: "联系售后",
          confirmButtonText: "确认",
        },
        document.querySelector("#app")
      );
      dialog.open();
    },
  },

  async mounted() {
    let path = this.$route.path;
    let checkAuthCode = false;
    if (path === "/checkAuthCode") {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "加载中...",
        forbidClick: true,
      });

      try {
        await this.checkAuthCode();
      } catch (error) {}

      Toast.clear();
      checkAuthCode = true;

      if (this.$route.query.redirect) {
        this.$router.replace(this.$route.query.redirect);
      } else {
        this.$router.replace("/");
      }
    }

    if (Util.getToken()) {
      this.$store.commit("setIsLogin", true);
      this.isShow = true;
    } else {
      this.$store.commit("setIsLogin", false);

      if (Util.isWechat()) {
        if (checkAuthCode) {
          this.isShow = true;
        } else {
          this.queryCode();
        }
      } else {
        this.isShow = true;
      }
    }
  },
};
</script>

<style lang="scss">
.icon-contact {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  font-size: 30rem;
  position: absolute;
  top: 75%;
  right: 10rem;
  z-index: 10000;
}

.van-toast {
  max-width: 40% !important;
  width: auto !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
</style>
