<template>
    <div class="full-screen">
        <div class="van-dialog__content">
            <van-nav-bar title="发布成功" left-text="返回" left-arrow @click-left="close()" />

            <div class="dialog-content">
                <div class="content">
                    <div class="icon">
                        <van-icon name="passed" size="60rem" color="#009dff" />
                    </div>
                    <div class="hint">发布成功</div>
                    <div class="detail">商品已成功发布，您可以在库存管理中找到该商品</div>

                    <div class="button-wrap">
                        <van-button type="info" to="productList">库存管理</van-button>
                        <van-button plain type="info" to="addProduct">库存发布</van-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: [],

    data() {
        return {
        }
    },
    computed: {},
    watch: {},

    methods: {
        close() {
            this.$router.back()
        },
    },
};

</script>
    
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
        margin-top: 30rem;
    }

    .hint {
        margin-top: 20rem;
        font-size: 20rem;
        font-weight: 800;
    }

    .detail {
        margin-top: 10rem;
        font-size: 12rem;
    }

    .button-wrap {
        margin: 50rem 0 30rem 0;

        :deep(.van-button) {
            border-radius: 2rem;
            font-size: 14rem;
            padding: 0 15rem;
            height: 30rem;
            margin-right: 10rem;
        }

        :deep(.van-button--info) {
            background: #009dff;
        }

        :deep(.van-button--plain) {
            color: #009dff;
            background: #fff;
        }
    }
}
</style>