<template>
  <van-dialog v-if="show" class="full-screen" v-model="show" :closeOnPopstate="true" confirm-button-color="#009dff">
    <van-nav-bar title="快速竞价" left-text="返回" left-arrow @click-left="close()" />

    <van-form id="bidForm" ref="form" label-width="84rem">
      <van-image class="img" :src="item.Image" lazy-load></van-image>

      <van-field v-model="item.Name" readonly label="商品名称" />
      <van-field v-model="item.OriginalPrice" readonly label="原价">
        <template #extra>
          <span class="currency">{{ item.CurrencyDesc }}</span>
        </template>
      </van-field>
      <van-field v-model="item.Price" readonly label="清仓价">
        <template #extra>
          <span class="currency">{{ item.CurrencyDesc }}</span>
        </template>
      </van-field>
      <van-field v-model="item.Stock" readonly label="库存" />
      <van-field v-model="item.MinOrder" readonly label="起订量" />

      <van-field
        v-model="price"
        required
        type="number"
        name="您的报价"
        label="您的报价"
        placeholder="请输入报价"
        :rules="[
          { required: true, message: '请填写报价' },
          { validator: validatePrice, message: '请输入有效报价' },
        ]"
      >
        <template #extra>
          <span class="currency">{{ item.CurrencyDesc }}</span>
        </template>
      </van-field>
      <van-field
        v-model="num"
        required
        type="number"
        name="竞价数量"
        label="竞价数量"
        placeholder="请输入竞价数量"
        :rules="[
          { required: true, message: '请填写竞价数量' },
          { validator: validateNumMinOrder, message: '低于起订数量，请修改' },
          { validator: validateNumStock, message: '大于库存数量，请修改' },
        ]"
      />
    </van-form>
    <div class="button-wrap">
      <van-button plain type="info" @click="close()">取消</van-button>
      <van-button type="info" @click="submit()">确认</van-button>
    </div>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";

import Util from "@/utils/util";
import { Toast } from "vant";
import EditContact from "@/views/Contact/EditContact.vue";

export default {
  name: "BidDialog",
  components: {},
  props: ["forward", "back", "refresh"],

  data() {
    return {
      show: false,
      item: {},
      price: "",
      num: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(item) {
      this.item = item;
      this.forward?.();

      this.show = true;
    },
    close() {
      this.show = false;
      this.back?.();
    },

    async submit() {
      this.$refs.form
        .validate()
        .then(async () => {
          let data = {
            ProductId: this.item.Id,
            ProductNum: this.num,
            Price: this.price,
          };
          await Service.createBidding(data);
          this.$toast.success("竞价成功");
          this.close();
          this.refresh();
        })
        .catch(() => {
          let form = document.querySelector("#bidForm");
          form.scrollTop = form.scrollHeight;
        });
    },

    validatePrice(val) {
      return val > 0;
    },

    validateNumMinOrder(val) {
      return val >= Number(this.item.MinOrder);
    },
    validateNumStock(val) {
      return val <= Number(this.item.Stock);
    },
  },
  created() {},
  async mounted() {
    // let watchRoute = this.$watch("$route", () => {
    //   if (!this.$route.path.startsWith("/bidDialog") && !this.$route.path.startsWith("/productDetail")) {
    //     this.show = false;
    //     watchRoute();
    //   }
    // });

    let subscribeResult = await Service.checkSubscribe();
    if (subscribeResult.Subscribe) {
      let checkAddResult = await Service.checkAddProduct();
      if (!checkAddResult.CanAddProduct) {
        var dialog = Util.createComponent(
          EditContact,
          {
            forward: () => {
              // this.$router.push('/')
            },
            back: () => {
              // this.$router.back()
            },
            title: "完善用户信息",
            hint: "为方便后续沟通，请补充联系方式",
            showBack: false,
          },
          document.querySelector("#app")
        );
        dialog.open();
      }
    } else {
      this.$router.push("/login");
    }
  },
};
</script>

<style lang="scss" scoped>
.button-wrap {
  margin: 50rem 0 30rem 0;
  text-align: center;

  :deep(.van-button) {
    border-radius: 2rem;
    font-size: 14rem;
    padding: 0 15rem;
    height: 30rem;
    margin: 0 15rem;
  }

  :deep(.van-button--info) {
    background: #009dff;
  }

  :deep(.van-button--plain) {
    color: #009dff;
    background: #fff;
  }
}

.img {
  display: block;
  width: 120rem;
  height: 120rem;
  margin: 10rem auto;
}

.currency {
  color: #ea3a3c;
}

form {
  margin: 0 26rem;
  overflow: scroll;
}
</style>
