<!--  -->
<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="top">
      <span>地址管理</span>
      <van-button icon="plus" @click="onEdit">新增</van-button>
    </div>
    <van-address-list v-model="addressId" :list="addressList" add-button-text="删除" @add="deleteAddress" @edit="onEdit"></van-address-list>
  </div>
</template>

<script>
import EditAddress from "@/views/Address/EditAddress";
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";
import PageTop from "@/components/PageTop";
import Confirm from "@/components/Confirm";

export default {
  name: "AddressList",
  components: { PageTop },
  props: [],

  data() {
    return {
      addressId: "",
      addressList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    async getList(showLoading) {
      let result = await Service.getAddressList(
        {
          pageSize: 100,
        },
        showLoading
      );

      this.addressList = result.Rows.map((v) => {
        return {
          id: v.Id,
          item: v,
          name: v.Contact,
          tel: v.Phone,
          address: Util.formatLocation(v, true),
        };
      });
    },

    async deleteAddress() {
      if (this.addressId) {
        let dialog = Util.createComponent(
          Confirm,
          {
            title: "删除地址",
            message: "此操作将删除地址，是否继续？",
            confirmButtonText: "确认",
            confirm: async () => {
              await Service.deleteAddress(
                {
                  Id: this.addressId,
                },
                false
              );
              Toast.success("地址已删除");
              this.addressId = "";
              this.getList(false);
            },
          },
          document.querySelector("#app")
        );
        dialog.open();
      } else {
        Toast.fail("请选择地址");
      }
    },

    onEdit(item) {
      var dialog = Util.createComponent(
        EditAddress,
        {
          forward: () => {
            this.$router.push("/addressList/editAddress");
          },
          back: () => {
            this.$router.back();
          },
          refresh: () => {
            this.getList(false);
          },
        },
        document.querySelector("#app")
      );
      dialog.open(item?.id);
    },
  },

  created() { },
  mounted() {
    this.getList(true);
    if (this.$route.path !== "/addressList") {
      this.$router.replace("/addressList");
    }
  },
};
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
}

.top {
  display: flex;
  justify-content: space-between;

  span {
    display: inline-block;
    line-height: 30rem;
    font-size: 17rem;
    margin-left: 10rem;
  }

  ::v-deep {
    .van-button {
      height: 30rem;
      border: 0;
      color: #1989fa;

      .van-button__text {
        margin-left: 5rem;
        font-size: 17rem;
      }
    }
  }
}

::v-deep {
  .van-address-item {
    .van-radio__label {
      min-width: 0;
    }

    .van-address-item__name {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .van-address-item__address {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .van-address-list {
    background-color: #f5f7fa;
    overflow: scroll;

    .van-radio__icon--checked .van-icon {
      background-color: #1989fa;
      border-color: #1989fa;
    }

    .van-address-list__bottom {
      max-width: 750px;
      left: 50%;
      transform: translateX(-50%);
    }

    .van-button--danger {
      color: #fff;
      background-color: #1989fa;
      border: 1px solid #1989fa;
    }
  }
}
</style>
