import axios from "axios";
import qs from "qs"; // qs这个包在install  axios 时就已经装了，因为 qs 是 axios 的依赖包
import { Toast } from "vant";
import Util from "@/utils/util";
import router from "@/router";

axios.defaults.withCredentials = false; // cookie
axios.defaults.timeout = 60000;
axios.loadingRequstCount = 0;
axios.loadingCount = 0;

axios.interceptors.request.use(
  (config) => {
    if (config.showLoading) {
      axios.loadingRequstCount++;

      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "加载中...",
        forbidClick: true,
      });
    }

    axios.loadingCount++;

    let token = Util.getToken();
    if (token) {
      config.headers["X-Client-Token"] = token;
    }

    return config;
  },
  (error) => {
    if (error?.config?.showLoading) {
      if (axios.loadingRequstCount > 0) {
        axios.loadingRequstCount--;
      }

      if (axios.loadingRequstCount <= 0) {
        // 手动清除 Toast
        Toast.clear();
      }
    }

    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response?.config?.showLoading) {
      if (axios.loadingRequstCount > 0) {
        axios.loadingRequstCount--;
      }

      if (axios.loadingRequstCount <= 0) {
        // 手动清除 Toast
        Toast.clear();
      }
    }

    if (response.data.code == 0) {
      let token = response.headers["X-Client-Token"] || response.headers["x-client-token"];
      if (token) {
        Util.setToken(token);
      }

      return response.data.data;
    } else {
      if (response.data.code == 4 || response.data.code == 1000) {
        Toast.fail("登录过期，请重新登录");
        Util.removeToken();
        router.push("/login");
      } else {
        Toast({
          duration: 3000,
          forbidClick: false,
          message: response.data.msg,
          getContainer: "#toast",
        });
      }
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error?.config?.showLoading) {
      if (axios.loadingRequstCount > 0) {
        axios.loadingRequstCount--;
      }

      if (axios.loadingRequstCount <= 0) {
        // 手动清除 Toast
        Toast.clear();
      }
    }

    Toast.fail("请求错误");
    return Promise.reject(error);
  }
);

axios.get = function (url, params, showLoading) {
  return axios({
    method: "GET",
    url,
    params,
    showLoading,
  });
};

axios.post = function (url, data, showLoading) {
  return axios({
    method: "POST",
    url: url,
    data: qs.stringify(data),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    showLoading,
  });
};

axios.postJson = function (url, data, showLoading) {
  return axios({
    method: "POST",
    url: url,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    showLoading,
  });
};

axios.postForm = function (url, data, showLoading) {
  return axios({
    method: "POST",
    url: url,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    showLoading,
  });
};

export default axios;
