<template>
    <div class="address" @click="$emit('click')">
        <div v-if="!address" class="add-contact">
            <van-icon name="add-square" color="#1989fa" size="40rem" />
            <div class="contact">收货信息</div>
        </div>
        <div v-else class="add-contact">
            <van-icon name="contact" />
            <div class="contact">
                <div>{{ address?.Contact }},{{ address?.Phone }}</div>
                <div class="address-line">
                    <span> {{ location }}</span>
                </div>
            </div>
        </div>
        <van-icon name="arrow" />
    </div>
</template>

<script>
import Util from "@/utils/util";

export default {
    name: "AddressCard",
    props: ["address"],
    components: {},
    data() {
        return {};
    },
    computed: {
        location() {
            return Util.formatLocation(this.address, true);
        },
    },

    watch: {},

    methods: {},
};
</script>

<style lang="scss" scoped>
.address {
    padding: 16rem;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
        content: "";
        height: 2rem;
        background: repeating-linear-gradient(-45deg, #ff6c6c 0, #ff6c6c 20%, transparent 0, transparent 25%, #1989fa 0, #1989fa 45%, transparent 0, transparent 50%);
        background-size: 80rem;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .add-contact {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;

        .contact {
            font-size: 14rem;
            flex: 1;
            line-height: 20rem;
            margin-left: 10rem;
            min-width: 0;
            word-break: break-all;

            .address-line {
                width: 100%;

                span {
                    width: 100%;
                    display: inline-block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
