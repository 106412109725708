import Request from "@/utils/request";

export default {
  checkAuthCode(data) {
    return Request.get("/api/WxGzh/CheckAuthCode", data, false);
  },

  report(data) {
    return Request.postJson("/api/Common/Report", data);
  },

  // 上传图片
  uploadImage(data) {
    return Request.postForm("/api/Common/uploadimage", data);
  },

  // 首页列表
  homeGetProductList(data) {
    return Request.get("/api/Home/GetProductList", data, false);
  },

  // 产品
  getProductList(data, showLoading) {
    return Request.get("/api/Product/GetProductList", data, showLoading);
  },
  getProductDetail(data) {
    return Request.get("/api/Product/GetProductDetail", data, true);
  },
  editProduct(data) {
    return Request.postJson("/api/Product/EditProduct", data, true);
  },
  deleteProduct(data) {
    return Request.postJson("/api/Product/DeleteProduct", data, true);
  },
  setProductFlag(data) {
    return Request.postJson("/api/Product/SetProductFlag", data, true);
  },
  checkAddProduct() {
    return Request.get("/api/Product/CheckAddProduct", null, true);
  },

  // 地址
  getAddressList(data, showLoading) {
    return Request.get("/api/Address/GetAddressList", data, showLoading);
  },
  getAddressDetail(data) {
    return Request.get("/api/Address/GetAddressDetail", data, false);
  },
  editAddress(data) {
    return Request.postJson("/api/Address/EditAddress", data, false);
  },
  deleteAddress(data, showLoading) {
    return Request.postJson("/api/Address/DeleteAddress", data, showLoading);
  },

  checkSubscribe() {
    return Request.get("/api/User/CheckSubscribe", null, true);
  },
  // 联系方式
  getContact() {
    return Request.get("/api/User/GetUserDetail", null, true);
  },
  editContact(data) {
    return Request.postJson("/api/User/EditUser", data, true);
  },

  //订单
  getOrderList(data, showLoading) {
    return Request.get("/api/Order/GetOrderList", data, showLoading);
  },

  getOrderDetail(data) {
    return Request.get("/api/Order/GetOrderDetail", data, true);
  },

  payOrder(data) {
    return Request.postJson("/api/Order/PayOrder", data, true);
  },
  shipOrder(data) {
    return Request.postJson("/api/Order/ShipOrder", data, true);
  },
  cancelOrder(data) {
    return Request.postJson("/api/Order/CancelOrder", data, true);
  },
  completeOrder(data) {
    return Request.postJson("/api/Order/CompleteOrder", data, true);
  },

  addOrderImage(data) {
    return Request.postJson("/api/Order/AddOrderImage", data);
  },
  deleteOrderImage(data) {
    return Request.postJson("/api/Order/DeleteOrderImage", data);
  },

  //竞价

  getBiddingList(data, showLoading) {
    return Request.get("/api/Bidding/GetBiddingList", data, showLoading);
  },

  setBiddingFlag(data) {
    return Request.postJson("/api/Bidding/SetBiddingFlag", data, true);
  },

  createBidding(data) {
    return Request.postJson("/api/Bidding/CreateBidding", data, true);
  },
  createOrder(data) {
    return Request.postJson("/api/Order/CreateOrder", data, true);
  },

  //求购
  getAskToBuyList(data, showLoading) {
    return Request.get("api/Home/GetAskToBuyList", data, showLoading);
  },

  //产品详情
  getHomeProductDetail(data, showLoading) {
    return Request.get("api/Home/GetProductDetail", data, showLoading);
  },

  //小红书分享
  getJsShareInfo(data, showLoading) {
    return Request.get("api/Xhs/GetJsShareInfo", data, showLoading);
  },
};
