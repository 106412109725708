<!--  -->
<template>
  <van-dialog v-if="show" v-model="show" class="full-screen" :closeOnPopstate="false">
    <van-nav-bar title="订单详情" left-text="返回" left-arrow @click-left="close()" />
    <div class="page">
      <div class="content">
        <ul>
          <li>
            <div class="item-wrapper list-state">
              <div class="title">当前订单状态：{{ FlagDesc }}</div>
              <div>{{ flagDescList[type][flag] }}</div>
            </div>

            <div v-if="(Contact && (!IsSeller || Flag == 2)) || ProductWarehouseType == 2" class="item-wrapper shipping-info">
              <div class="title">{{ ProductWarehouseType == 2 ? "取货信息" : "发货信息" }}</div>
              <div class="shipping-address">
                <div v-if="ProductWarehouseType == 1" class="address-detail">
                  <span>收件人姓名：</span>
                  <span>{{ Contact }}</span>
                </div>
                <!-- <div class="address-detail">
                  <span> 收件人电话：</span><span>{{ Phone }}</span>
                                                                  </div> -->
                <div class="address-detail" v-if="ProductWarehouseType == 1 || ![1, 4, 6].some((v) => v == Flag)">
                  <span> {{ "地址1：" }}</span><span>{{ Address1 }}</span>
                </div>
                <div class="address-detail" v-if="ProductWarehouseType == 1 || ![1, 4, 6].some((v) => v == Flag)">
                  <span> {{ "地址2：" }}</span><span>{{ Address2 }}</span>
                </div>
                <div class="address-detail" v-if="ProductWarehouseType == 1 || ![1, 4, 6].some((v) => v == Flag)">
                  <span> 邮编：</span><span>{{ ZipCode }}</span>
                </div>
                <div class="address-detail">
                  <span> 城市：</span><span>{{ City }}</span>
                </div>
                <div class="address-detail">
                  <span> 省份：</span><span>{{ Province }}</span>
                </div>
                <div class="address-detail">
                  <span> 国家：</span><span>{{ CountryDesc }}</span>
                </div>
              </div>
              <div class="list-btn" v-if="this.type == 2 || ProductWarehouseType == 2">
                <van-button round type="info" @click="copyAddress()">复制{{ ProductWarehouseType == 2 ? "取货信息" : "发货信息" }}</van-button>
              </div>
            </div>

            <!-- 
            <div class="item-wrapper">
              <div class="title">买家信息</div>
              <div>
                <div class="label">
                  买家ID
                  <div>{{ UserId }}</div>
                </div>
              </div>
              <div>
                <div class="label">
                  联系手机
                  <span>{{ Phone }}</span>
                </div>
              </div>
                                                                                                                                                                                                            </div> -->

            <div class="item-wrapper product-info">
              <div class="title">商品信息</div>
              <div class="list-data">
                <van-image :src="ProductImage" />
                <div class="state">
                  <div class="van-multi-ellipsis--l2 title">{{ ProductName }}</div>
                  <div class="price">
                    {{ ProductPriceDesc }}
                    <div>x{{ ProductNum }}</div>
                    <div>{{ ProductAmountDesc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item-wrapper">
              <div class="title">订单信息</div>
              <div class="price-item">
                <div class="price-item-label">
                  商品总价:
                  <span> {{ ProductAmountCNYDesc }}</span>
                </div>
                <div class="price-item-label">
                  平台服务费:
                  <span> {{ this.type == 1 ? ServiceFeeCNYDesc : SellerServiceFeeCNYDesc }} </span>
                </div>

                <div v-if="ProductCurrency != 1" class="price-item-label">
                  汇率:
                  <span> {{ ExchangeRate }}</span>
                </div>
                <div class="price-item-label">
                  {{ this.type == 1 ? "实付款" : "实收款" }}:
                  <span>{{ this.type == 1 ? AmountCNYDesc : SellerAmountCNYDesc }}</span>
                </div>
              </div>
              <div class="label">
                订单编号
                <span>
                  {{ id }}
                  <van-icon name="notes-o" @click="copyOrderNum(id)" />
                </span>
              </div>
              <div class="label">
                创建时间
                <span>{{ CreateTime }}</span>
              </div>
              <div v-if="PayTime" class="label">
                付款时间
                <span>{{ PayTime }}</span>
              </div>
            </div>
            <div v-if="Flag == 3 || (!IsSeller && Flag == 6)" class="item-wrapper">
              <div class="title">{{ Flag == 3 ? "发货凭证" : "支付凭证" }}</div>
              <van-uploader v-model="imageList" :after-read="afterRead" :before-delete="deleteImage" :deletable="IsSeller" :max-count="IsSeller && Flag == 3 ? undefined : imageList.length - 1" image-fit="contain" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";

export default {
  name: "OrderDetail",
  components: {},
  props: ["forward", "back", "refresh"],

  data() {
    return {
      show: false,
      IsSeller: "",
      id: "",
      type: "",
      flag: "",
      flagDescList: {
        1: {
          1: "等待买家付款",
          2: "等待卖家发货",
          3: "等待买家确认收货",
        },
        2: {
          1: "等待买家付款。",
          2: "等待卖家发货。",
          3: "等待买家确认收货。",
        },
      },
      Flag: "",
      FlagDesc: "",
      UserId: "",
      Phone: "",
      Address1: "",
      Address2: "",
      City: "",
      ZipCode: "",
      Province: "",
      Contact: "",
      CountryDesc: "",
      ProductImage: "",
      ProductName: "",
      ProductPriceDesc: "",
      ProductNum: "",
      ProductCurrency: "",
      ProductWarehouseType: "",

      AmountDesc: "",
      SellerAmountDesc: "",
      ServiceFeeDesc: "",
      SellerServiceFeeDesc: "",

      ExchangeRate: "",
      AmountCNYDesc: "",
      SellerAmountCNYDesc: "",
      ServiceFeeCNYDesc: "",
      SellerServiceFeeCNYDesc: "",

      CreateTime: "",
      PayTime: "",
      ProductAmountDesc: "",
      ProductAmountCNYDesc: "",
      imageList: [],
      OrderId: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(id, type, flag) {
      this.id = id;
      this.type = type;
      this.flag = flag;
      this.forward?.();
      this.show = true;
      this.load();
    },

    async load() {
      let params = {
        id: this.id,
      };

      let data = await Service.getOrderDetail(params);
      this.FlagDesc = data.FlagDesc;

      this.UserId = data.UserId;
      this.IsSeller = data.IsSeller;
      this.Flag = data.Flag;
      this.OrderId = data.Id;
      this.Phone = data.Phone;
      this.ProductImage = data.ProductImage;
      this.ProductName = data.ProductName;
      this.ProductPriceDesc = data.ProductPriceDesc;
      this.ProductNum = data.ProductNum;
      this.ProductCurrency = data.ProductCurrency;
      (this.ProductWarehouseType = data.ProductWarehouseType), (this.Address1 = data.Address1);
      this.Address2 = data.Address2;
      this.City = data.City;
      this.ZipCode = data.ZipCode;
      this.Province = data.Province;
      this.Contact = data.Contact;
      this.CountryDesc = data.CountryDesc;

      this.AmountDesc = data.AmountDesc;
      this.SellerAmountDesc = data.SellerAmountDesc;
      this.ServiceFeeDesc = data.ServiceFeeDesc;
      this.SellerServiceFeeDesc = data.SellerServiceFeeDesc;

      this.ExchangeRate = data.ExchangeRate;
      this.AmountCNYDesc = data.AmountCNYDesc;
      this.SellerAmountCNYDesc = data.SellerAmountCNYDesc;
      this.ServiceFeeCNYDesc = data.ServiceFeeCNYDesc;
      this.SellerServiceFeeCNYDesc = data.SellerServiceFeeCNYDesc;

      this.CreateTime = data.CreateTime;
      this.PayTime = data.PayTime;
      this.ProductAmountDesc = data.ProductAmountDesc;
      this.ProductAmountCNYDesc = data.ProductAmountCNYDesc;

      let imageType = data.Flag == 6 ? 1 : 2;
      this.imageList = data.ImageList.filter((v) => v.Type == imageType).map((v) => {
        return { Id: v.Id, url: v.Image };
      });
    },

    close() {
      this.show = false;
      this.back?.();
    },
    copyAddress() {
      let addressList = [];

      if (this.ProductWarehouseType == 1) {
        addressList.push(`收件人姓名：${this.Contact}`);
      }
      if (this.ProductWarehouseType == 1 || (this.Flag != 1 && this.Flag != 6)) {
        addressList.push(`地址1：${this.Address1}`);
        addressList.push(`地址2：${this.Address2}`);
        addressList.push(`邮编：${this.ZipCode}`);
      }
      addressList.push(`城市：${this.City}`);
      addressList.push(`省份：${this.Province}`);
      addressList.push(`国家：${this.CountryDesc}`);

      let address = addressList.join("\n");
      Util.copyText(address);
      Toast("复制成功");
    },
    copyOrderNum(id) {
      Util.copyText(id);
      Toast("复制成功");
    },
    afterRead(file) {
      let files = [];
      if (Array.isArray(file)) {
        files = file;
      } else {
        files.push(file);
      }
      files.forEach(async (item) => {
        try {
          item.status = "uploading";
          let data = {
            FileName: item.file.name,
            File: item.file,
          };
          let result = await Service.uploadImage(data);

          if (this.imageList.some((v) => v.url == result.Url)) {
            let i = this.imageList.indexOf(item);
            if (i > -1) {
              this.imageList.splice(i, 1);
              Toast("已过滤重复图片");
            }
          } else {
            item.url = result.Url;
            let dataImage = {
              OrderId: this.OrderId,
              Type: 2,
              Image: item.url,
            };
            let resultImage = await Service.addOrderImage(dataImage);
            item.Id = resultImage.Id;
            Toast.success("上传成功");
            item.status = "done";
          }
        } catch (error) {
          Toast("文件上传失败：" + error);
          let i = this.imageList.indexOf(item);
          if (i > -1) {
            this.imageList.splice(i, 1);
          }
        }
      });
    },

    async deleteImage(item) {
      let data = {
        Id: item.Id,
        OrderId: this.OrderId,
      };
      await Service.deleteOrderImage(data);
      Toast.success("删除成功");
    },
  },

  created() { },
  mounted() {
    let watchRoute = this.$watch("$route", () => {
      if (!this.$route.path.startsWith("/orderDetail")) {
        this.show = false;
        watchRoute();
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.full-screen {
  background-color: #f5f7fa;
}

.page {
  height: 100%;
  overflow: hidden;

  .header {
    padding-bottom: 0;
  }

  .content {
    overflow-y: auto;
    padding: 0;
  }

  .van-list {
    padding: 0 10rem;
  }
}

ul {
  li {
    background-color: #f5f7fa;
    margin-bottom: 10rem;
    border-radius: 10rem;
    padding-left: 10rem;
    padding-top: 5rem;

    .list-state {
      margin-top: 10rem;

      :last-child {
        font-size: 13rem;
        color: #88898a;
        padding: 3rem 15rem 8rem 25rem;
      }
    }

    .item-wrapper {
      background-color: white;
      border-radius: 10rem;
      margin: 0 10rem 10rem 0;
      padding: 5rem;

      .price-item {
        border-bottom: #f5f7fa 1px ridge;
        line-height: 18rem;

        :last-child {
          span {
            color: #ee0a24;
          }
        }
      }

      .price-item-label {
        padding: 10rem 10rem 10rem 0;
        font-size: 13rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;

        span {
          font-weight: bold;
          font-size: 15rem;
          margin-left: 10rem;
        }
      }

      .label {
        padding: 10rem 10rem 10rem 0;
        font-size: 13rem;
        font-weight: 500;
        line-height: 18rem;
        display: flex;
        justify-content: space-between;

        span {
          margin-left: 10rem;
        }
      }

      .detail {
        text-align: end;
        margin-right: 22rem;
        font-size: 13rem;
        color: #ee0a24;

        span {
          flex: 1;
          color: #88898a;
        }
      }
    }
  }

  .title {
    font-size: 14rem;
    font-weight: 900;
    color: black;

    div {
      display: flex;
      justify-content: space-between;
    }
  }

  .shipping-info {
    .shipping-address {
      display: flex;
      flex-direction: column;

      .address-detail {
        display: flex;
        flex-direction: row;
        padding: 5rem 5rem 5rem 0;
        font-size: 13rem;
        font-weight: 500;

        :first-child {
          display: block;
          width: 85rem;
        }

        :last-child {
          flex: 1;
          min-width: 0;
          word-break: break-all;
        }
      }
    }
  }

  .product-info {
    .list-data {
      display: flex;
      padding: 20rem 10rem 10rem 10rem;

      .van-image {
        flex-shrink: 0;
        width: 100rem;
        height: 100rem;
        margin: 0 10rem 0 0;
      }

      .state {
        flex: 1;
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .title {
          font-size: 13rem;
          font-weight: bold;
          line-height: 20rem;
          height: 40rem;
        }

        .price {
          height: 60rem;
          color: black;
          font-size: 13rem;
          font-weight: bold;
          margin-right: 10rem;
          text-align: right;

          div {
            color: #ee0a24;
            padding-top: 10rem;
            text-align: end;
            font-weight: 500;
          }
        }
      }

      .freight {
        font-size: 15rem;

        text-align: end;
        padding: 0 20rem 20rem 0;
      }

      .freight+div {
        color: #88898a;
        font-size: 13rem;
        text-align: end;
        padding: 0 20rem 20rem 0;

        :last-child {
          font-weight: 600;

          color: black;
        }
      }
    }

    :last-child {
      text-align: end;
    }
  }
}

.list-btn {
  text-align: right;

  ::v-deep {
    .van-button--normal {
      padding: 0 15rem;
    }

    .van-button {
      background-color: #f5f7fa;
      color: black;
      font-size: 13rem;
      height: 30rem;
      margin-left: 15rem;
      border: 0;
    }

    .van-button__content {
      background-color: #f5f7fa;
    }
  }

  .edit {
    color: #009dff;
    background-color: #e8edff;

    :deep(.van-button__content) {
      background-color: #e8edff;
    }
  }
}

.end {
  padding-left: 120rem;
  width: max-content;
  background-color: white;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60rem;
  margin: 0 10rem;
  font-size: 14rem;

  :deep(.van-button) {
    height: 35rem;
  }
}
</style>
