import Vue from "vue";
import VueRouter from "vue-router";
import Util from "@/utils/util";

Vue.use(VueRouter);

import HomePage from "@/views/Home/HomePage";
import Login from "@/views/Login/Login";
import Rule from "@/views/Home/Rule";
import ProductDetail from "@/views/Home/ProductDetail";

//竞价管理
import BiddingList from "@/views/Bidding/BiddingList";

// 库存管理
import AddProduct from "@/views/Product/AddProduct";
import AddProductSuccess from "@/views/Product/AddProductSuccess";
import ProductList from "@/views/Product/ProductList";

// 地址管理
import AddressList from "@/views/Address/AddressList";

//订单管理
import OrderList from "@/views/Order/OrderList";

//联系方式
import Contact from "@/views/Contact/Contact";
import CustomerService from "@/views/CustomerService/CustomerService";

const routes = [
  {
    path: "/",
    component: HomePage,
    meta: {
      anonymous: true,
    },
  },

  {
    path: "/productDetail",
    component: ProductDetail,
    meta: {
      anonymous: true,
    },
    children: [
      {
        path: "bidDialog",
        component: ProductDetail,
      },
      {
        path: "payDialog",
        component: ProductDetail,
      },
    ],
  },
  {
    path: "/rule",
    component: Rule,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/customerService",
    component: CustomerService,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/bidDialog",
    component: HomePage,
  },
  {
    path: "/payDialog",
    component: HomePage,
  },

  {
    path: "/index",
    component: HomePage,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/checkAuthCode",
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/login",
    component: Login,
    meta: {
      anonymous: true,
    },
  },
  {
    path: "/addProduct",
    component: AddProduct,
    children: [
      {
        path: "address",
        component: AddProduct,
      },
    ],
  },
  {
    path: "/editProductFromList",
    component: ProductList,
    children: [
      {
        path: "address",
        component: ProductList,
      },
    ],
  },
  {
    path: "/addProductSuccess",
    component: AddProductSuccess,
  },
  {
    path: "/productList",
    component: ProductList,
  },

  {
    path: "/addressList",
    component: AddressList,
    children: [
      {
        path: "editAddress",
        component: AddressList,
      },
    ],
  },

  {
    path: "/orderList",
    component: OrderList,
  },
  {
    path: "/orderDetail",
    component: OrderList,
  },
  {
    path: "/payOrder",
    component: OrderList,
    children: [
      {
        path: "addressList",
        component: OrderList,
        children: [
          {
            path: "orderAddress",
            component: OrderList,
          },
        ],
      },
      {
        path: "payOrderImage",
        component: OrderList,
      },
    ],
  },
  {
    path: "/shipOrder",
    component: OrderList,
  },

  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/biddingList",
    component: BiddingList,
  },
  {
    path: "*",
    component: HomePage,
    children: [
      { path: "*", component: Error },
      { path: "/error", component: Error },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.anonymous) {
    next();
  } else {
    if (Util.getToken()) {
      next();
    } else {
      next("/login");
    }
  }
});

export default router;
