<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="tab-wrapper">
      <van-tabs v-model="flag" @click="onFlag" color="#009dff" line-width="70rem">
        <van-tab :name="3">
          <template #title>
            出售中
            <span v-if="flagCount3 || flagCount3 === 0">({{ flagCount3 }})</span>
          </template>
        </van-tab>
        <van-tab :name="1">
          <template #title>
            待审核
            <span v-if="flagCount1 || flagCount1 === 0">({{ flagCount1 }})</span>
          </template>
        </van-tab>
        <van-tab :name="20">
          <template #title>
            草稿中
            <span v-if="flagCount20 || flagCount20 === 0">({{ flagCount20 }})</span>
          </template>
        </van-tab>
      </van-tabs>
    </div>

    <div class="sub-nav">
      <van-dropdown-menu active-color="#009dff">
        <van-dropdown-item v-model="orderByCreateTime" :options="orderByCreateTimeList" @change="getList(true)" />
        <van-dropdown-item v-model="category" :options="categoryList" @change="getList(true)" />
      </van-dropdown-menu>
      <van-button v-if="flag != 1" class="batch" round type="info" icon-prefix="iconfont" :icon="batch ? '' : 'batch'" :text="batch ? '取消批量' : '批量操作'" @click="toggleBatchMode"></van-button>
    </div>

    <div class="content">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <ul>
          <li v-for="item in listData" :key="item.Id">
            <van-checkbox v-model="item.selected" @click="onItemSelected" :class="{ batch }">
              <div class="list-item">
                <div class="list-data">
                  <van-image :src="item.Image" lazy-load />
                  <div class="state">
                    <div class="van-multi-ellipsis--l2 title">
                      {{ item.Name }}
                    </div>
                    <div v-if="item.Flag == 2" class="van-ellipsis remark">{{ item.Remark }}</div>
                    <div class="amount">
                      <span>库存</span><span>{{ item.Stock }}</span>
                    </div>
                    <div class="price">
                      {{ item.PriceDesc }} <span class="line">{{ item.OriginalPriceDesc }}</span>
                    </div>
                  </div>
                </div>

                <div class="list-btn" v-if="!batch">
                  <van-button v-if="flag == 3" round type="info" @click="soldoutProduct(item.Id)">下架</van-button>
                  <van-button v-if="flag == 20" round type="info" @click="deleteProduct(item.Id)">删除</van-button>
                  <van-button v-if="flag != 1" class="edit" round type="info" @click="editProduct(item.Id)">编辑</van-button>
                </div>
              </div>
            </van-checkbox>
          </li>
        </ul>
      </van-list>
    </div>

    <div class="end">
      <van-button round block type="info" color="#009dff" v-show="!batch" @click="addProduct">发布商品</van-button>

      <van-checkbox class="checkbox" v-model="checkAll" type="primary" @click="onCheckAll" v-if="batch">全选</van-checkbox>
      <div>
        <van-button class="end-under" round type="info" color="#009dff" @click="soldoutBatch" v-if="batch && flag == 3">批量下架</van-button>
        <van-button class="end-del" round type="info" color="#009dff" @click="deleteBatch" v-if="batch && flag == 20">批量删除</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import Confirm from "@/components/Confirm";
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";
import { Dialog } from "vant";
import EditProduct from "./EditProduct.vue";
import PageTop from "@/components/PageTop";

export default {
  name: "ProductList",
  components: { PageTop },

  data() {
    return {
      orderByCreateTime: 1,
      orderByCreateTimeList: [
        { text: "创建时间-降序", value: 1 },
        { text: "创建时间-升序", value: 2 },
      ],

      category: "",
      categoryList: [{ text: "商品分类", value: "" }],

      flag: 3,

      flagCount3: "",
      flagCount1: "",
      flagCount20: "",

      batch: false,
      checkAll: false,

      finished: false,
      loading: false,
      listData: [],

      pageSize: 20,
      pageIndex: 1,
    };
  },
  computed: {},
  watch: {},

  methods: {
    async getList(reset) {
      if (reset) {
        this.finished = false;
        this.pageIndex = 1;
        this.listData = [];
      }

      this.loading = true;

      let params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,

        category: this.category,
        flag: this.flag,
        orderByCreateTime: this.orderByCreateTime,
      };

      let data = await Service.getProductList(params, reset);

      if (reset) {
        this.pageIndex = 1;
        this.listData = [];
      }

      this.loading = false;

      this.flagCount1 = data.Flag1Count;
      this.flagCount20 = data.Flag20Count;
      this.flagCount3 = data.Flag3Count;

      this.categoryList.splice(1);
      data.CategoryList.forEach((item) => {
        this.categoryList.push({
          text: item.Name,
          value: item.Id,
        });
      });

      this.total = data.Total;
      this.pageIndex++;

      data.Rows.forEach((item) => {
        if (this.batch && this.checkAll) {
          item.selected = true;
        } else {
          item.selected = false;
        }

        this.listData.push(item);
      });

      if (this.listData.length >= this.total) {
        this.finished = true;
      }

      if (this.listData.length == 0 && this.batch) {
        this.batch = false;
      }
    },

    toggleBatchMode() {
      this.batch = !this.batch;

      if (this.batch) {
        if (this.listData.length == 0) {
          Toast.fail("暂无商品");
          this.batch = false;
          return;
        }

        this.listData.forEach((listData) => {
          listData.selected = false;
        });

        this.checkAll = false;
      }
    },

    onLoad() {
      this.getList(false);
    },

    onFlag() {
      if (this.flag == 1 && this.batch) {
        this.batch = false;
      }

      if (this.batch) {
        this.listData.forEach((listData) => {
          listData.selected = false;
        });
      }

      this.getList(true);
    },

    onItemSelected() {
      let checkAll = this.listData.every((v) => v.selected);
      this.checkAll = checkAll;
    },

    onCheckAll() {
      this.listData.forEach((listData) => {
        listData.selected = this.checkAll;
      });
    },

    addProduct() {
      this.$router.push("/addProduct");
    },

    soldoutProduct(id) {
      let dialog = Util.createComponent(
        Confirm,
        {
          title: "下架商品",
          message: "此操作将选中的商品设置为草稿，是否继续？",
          confirmButtonText: "继续下架",
          confirm: async () => {
            await Service.setProductFlag({
              id: id,
              flag: 0,
            });
            Toast.success("下架成功");
            this.getList(true);
          },
        },
        document.querySelector("#app")
      );
      dialog.open();
    },

    deleteProduct(id) {
      var dialog = Util.createComponent(
        Confirm,
        {
          title: "删除选中的商品",
          message: "此操作将永久删除选中的商品，是否继续？",
          confirmButtonText: "继续删除",
          confirm: async () => {
            await Service.deleteProduct({
              id: id,
            });
            Toast.success("删除成功");
            this.getList(true);
          },
        },
        document.querySelector("#app")
      );

      dialog.open();
    },

    editProduct(id) {
      if (this.flag == 3) {
        let dialog = Util.createComponent(
          Confirm,
          {
            title: "修改商品",
            message: "此操作将选中的商品设置为草稿，是否继续？",
            confirmButtonText: "继续修改",
            confirm: async () => {
              this.showEditProductDialog(id);
            },
          },
          document.querySelector("#app")
        );
        dialog.open();
      } else {
        this.showEditProductDialog(id);
      }
    },

    showEditProductDialog(id) {
      let editDialog = Util.createComponent(
        EditProduct,
        {
          forward: () => {
            this.$router.push("/editProductFromList");
          },
          back: () => {
            this.$router.back();
          },
          refresh: () => {
            this.getList(true);
          },
        },
        document.querySelector("#app")
      );
      editDialog.open(id);
    },

    soldoutBatch() {
      var ids = this.getSelectedIds();

      let dialog = Util.createComponent(
        Confirm,
        {
          title: "下架商品",
          message: "此操作将选中的商品设置为草稿，是否继续？",
          confirmButtonText: "继续下架",
          confirm: async () => {
            await Service.setProductFlag({
              id: ids.join(","),
              flag: 0,
            });
            Toast.success("下架成功");
            this.getList(true);
          },
        },
        document.querySelector("#app")
      );
      dialog.open();
    },

    deleteBatch() {
      var ids = this.getSelectedIds();

      let dialog = Util.createComponent(
        Confirm,
        {
          title: "删除选中的商品",
          message: "此操作将永久删除选中的商品，是否继续？",
          confirmButtonText: "继续删除",
          confirm: async () => {
            await Service.deleteProduct({
              id: ids.join(","),
            });
            Toast.success("删除成功");
            this.getList(true);
          },
        },
        document.querySelector("#app")
      );
      dialog.open();
    },

    getSelectedIds() {
      let ids = [];
      this.listData.forEach((v) => {
        if (v.selected) {
          ids.push(v.Id);
        }
      });
      if (ids.length === 0) {
        Toast.fail("请勾选商品");
        throw "selection empty";
      }
      return ids;
    },
  },

  mounted() {
    if (this.$route.path !== "/productList") {
      this.$router.replace("/productList");
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  height: 100%;

  .header {
    padding-bottom: 0;
  }

  .content {
    overflow-y: auto;
    padding: 0;
  }

  .van-list {
    padding: 0 10rem;
  }
}

.tab-wrapper {
  width: auto;
  height: 40rem;
  background-color: rgb(255, 255, 255);

  ::v-deep {
    .van-tab {
      font-size: 15rem;
      line-height: 20rem;
      padding: 0 4rem;
    }

    .van-tabs__wrap {
      height: 44rem;
    }

    .van-tabs__nav--line {
      padding-bottom: 10rem;
    }

    .van-tabs__line {
      height: 3rem;
    }
  }
}

.sub-nav {
  position: relative;

  ::v-deep {
    .batch {
      position: absolute;
      top: 0;
      right: 0;
      height: 35rem;
      border: 0;
      background-color: #f5f7fa;
      color: black;
      font-size: 13rem;
      line-height: 22rem;
    }
  }

  ::v-deep {
    .van-dropdown-menu {
      background-color: #f5f7fa;
    }

    .van-dropdown-menu__bar {
      background-color: #f5f7fa;
      width: 260rem;
      height: 35rem;
      color: aliceblue;
      box-shadow: none;
      left: -11rem;
    }

    .van-dropdown-menu__title {
      color: #32323385;
      font-size: 12rem;
      line-height: 22rem;
      padding: 0 8rem;
    }

    .van-dropdown-item__option--active {
      color: blue;
    }

    .van-cell__value {
      flex: 0 1 auto;
    }
  }
}

ul {
  li {
    ::v-deep {
      .van-checkbox__icon {
        display: none;
      }

      .van-checkbox__icon--checked .van-icon {
        background-color: #009dff;
        border-color: #009dff;
      }

      .van-checkbox__label {
        margin-left: 0;
        flex: 1;
        overflow: hidden;
      }

      .batch {
        .van-checkbox__icon {
          display: block;
          margin-right: 10rem;
        }
      }
    }

    .list-item {
      flex: 1;
      background-color: white;
      border-radius: 10rem;
      margin-bottom: 15rem;

      .list-data {
        display: flex;
        padding: 20rem 10rem 10rem 10rem;

        .van-image {
          flex-shrink: 0;
          width: 100rem;
          height: 100rem;
          margin: 0 10rem 0 0;
        }

        .state {
          flex: 1;
          overflow: hidden;

          .title {
            font-size: 13rem;
            font-weight: bold;
            line-height: 20rem;
            height: 40rem;
          }

          .remark {
            font-size: 13rem;
            color: red;
          }

          .amount {
            margin-top: 5rem;
            color: darkgray;
            font-size: 13rem;

            span {
              padding: 3rem;
            }
          }

          .price {
            margin-top: 10rem;
            color: red;
            font-size: 16rem;
            font-weight: bold;

            .line {
              font-size: 12rem;
              color: #999;
              text-decoration: line-through;
              margin-left: 8rem;
            }
          }
        }
      }

      .list-btn {
        padding: 0 15rem 10rem 110rem;
        text-align: right;

        ::v-deep {
          .van-button {
            font-size: 12rem;
            height: 30rem;
            margin-left: 15rem;
          }

          .van-button--normal {
            padding: 0 15rem;
          }

          .van-button {
            background-color: #f5f7fa;
            color: black;
            border: 0;
          }

          .van-button__content {
            background-color: #f5f7fa;
          }
        }

        .edit {
          color: #009dff;
          background-color: #e8edff;

          :deep(.van-button__content) {
            background-color: #e8edff;
          }
        }
      }
    }
  }
}

.end {
  background-color: white;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60rem;
  margin: 0 10rem;
  font-size: 14rem;

  :deep(.van-button) {
    height: 35rem;
  }

  .end-del {
    margin-left: 15rem;
  }
}
</style>
