<!--  -->
<template>
  <van-dialog v-if="show" v-model="show" class="full-screen" :closeOnPopstate="false">
    <van-nav-bar title="修改商品" left-text="返回" left-arrow @click-left="close()" />

    <div class="dialog-content">
      <ProductEditor ref="productEditor" addAddressUrl="/editProductFromList/address" @submit="onSubmit" />
    </div>
</van-dialog>
</template>

<script>
import ProductEditor from "./ProductEditor";
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";

export default {
  components: {
    ProductEditor,
  },
  props: ["forward", "back", "refresh"],

  data() {
    return {
      show: false,
    };
  },
  computed: {},
  watch: {
    $route() {
      if (!this.$route.path.startsWith("/editProductFromList")) {
        this.show = false;
      }
    },
  },
  methods: {
    onSubmit(flag) {
      if (flag == 0) {
        Toast.success("保存成功");
        this.refresh?.();
      } else if (flag == 1) {
        this.$router.push("/addProductSuccess");
      }
    },

    open(id) {
      this.forward?.();
      this.show = true;

      this.$nextTick(() => {
        this.$refs.productEditor.load(id);
      });
    },

    close() {
      this.show = false;
      this.back?.();
    },
  },

  created() { },
  mounted() { },
};
</script>
<style lang="scss" scoped></style>
