var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('van-dialog',{staticClass:"full-screen",attrs:{"closeOnPopstate":true,"confirm-button-color":"#009dff"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-nav-bar',{attrs:{"title":"快速竞价","left-text":"返回","left-arrow":""},on:{"click-left":function($event){return _vm.close()}}}),_c('van-form',{ref:"form",attrs:{"id":"bidForm","label-width":"84rem"}},[_c('van-image',{staticClass:"img",attrs:{"src":_vm.item.Image,"lazy-load":""}}),_c('van-field',{attrs:{"readonly":"","label":"商品名称"},model:{value:(_vm.item.Name),callback:function ($$v) {_vm.$set(_vm.item, "Name", $$v)},expression:"item.Name"}}),_c('van-field',{attrs:{"readonly":"","label":"原价"},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm.item.CurrencyDesc))])]},proxy:true}],null,false,3013259389),model:{value:(_vm.item.OriginalPrice),callback:function ($$v) {_vm.$set(_vm.item, "OriginalPrice", $$v)},expression:"item.OriginalPrice"}}),_c('van-field',{attrs:{"readonly":"","label":"清仓价"},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm.item.CurrencyDesc))])]},proxy:true}],null,false,3013259389),model:{value:(_vm.item.Price),callback:function ($$v) {_vm.$set(_vm.item, "Price", $$v)},expression:"item.Price"}}),_c('van-field',{attrs:{"readonly":"","label":"库存"},model:{value:(_vm.item.Stock),callback:function ($$v) {_vm.$set(_vm.item, "Stock", $$v)},expression:"item.Stock"}}),_c('van-field',{attrs:{"readonly":"","label":"起订量"},model:{value:(_vm.item.MinOrder),callback:function ($$v) {_vm.$set(_vm.item, "MinOrder", $$v)},expression:"item.MinOrder"}}),_c('van-field',{attrs:{"required":"","type":"number","name":"您的报价","label":"您的报价","placeholder":"请输入报价","rules":[
        { required: true, message: '请填写报价' },
        { validator: _vm.validatePrice, message: '请输入有效报价' },
      ]},scopedSlots:_vm._u([{key:"extra",fn:function(){return [_c('span',{staticClass:"currency"},[_vm._v(_vm._s(_vm.item.CurrencyDesc))])]},proxy:true}],null,false,3013259389),model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('van-field',{attrs:{"required":"","type":"number","name":"竞价数量","label":"竞价数量","placeholder":"请输入竞价数量","rules":[
        { required: true, message: '请填写竞价数量' },
        { validator: _vm.validateNumMinOrder, message: '低于起订数量，请修改' },
        { validator: _vm.validateNumStock, message: '大于库存数量，请修改' },
      ]},model:{value:(_vm.num),callback:function ($$v) {_vm.num=$$v},expression:"num"}})],1),_c('div',{staticClass:"button-wrap"},[_c('van-button',{attrs:{"plain":"","type":"info"},on:{"click":function($event){return _vm.close()}}},[_vm._v("取消")]),_c('van-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.submit()}}},[_vm._v("确认")])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }