<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="content">
      <img :src="require('@/assets/images/contact.png')" alt="" />
    </div>
  </div>
</template>

<script>
import PageTop from "@/components/PageTop";

export default {
  components: { PageTop },
  props: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.page {
  .title {
    color: #000;
  }

  .content {
    padding: 0;

    img {
      width: 100%;
    }
  }
}
</style>
