<template>
  <div style="text-align: center">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  name: "qrCode",
  props: ["url"],

  data() {
    return {};
  },
  methods: {
    makeQrCode() {
      let opts = {
        errorCorrectionLevel: "H",
        type: "image/png",
        quality: 0.3,
        width: 200,
        height: 200,
        text: "xxx",
        color: {
          dark: "#333333",
          light: "#fff",
        },
      };
      QRCode.toCanvas(this.$refs.canvas, this.url, opts);
    },
  },
  mounted() {
    this.makeQrCode();
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
