<!--  -->
<template>
    <van-dialog v-if="show" v-model="show" class="full-screen" :closeOnPopstate="false">
        <van-nav-bar title="收货地址" left-text="返回" left-arrow @click-left="close()" />

        <div class="top"><span>常用地址</span> <van-button icon="plus" @click="onEdit"> 新增</van-button></div>
        <van-address-list v-model="addressId" :list="addressList" add-button-text="确定" @add="ok()" @edit="onEdit"> </van-address-list>
    </van-dialog>
</template>

<script>
import EditAddress from "@/views/Address/EditAddress";
import Service from "@/utils/service";
import Util from "@/utils/util";
import { Toast } from "vant";

export default {
    name: "OrderAddress",
    components: {},
    props: ["forward", "back", "refresh", "submit", "path"],

    data() {
        return {
            show: true,
            addressId: "",
            addressList: [],
        };
    },
    computed: {},
    watch: {},
    methods: {
        async refreshAddressList() {
            let result = await Service.getAddressList(
                {
                    pageSize: 100,
                },
                true
            );

            this.addressList = result.Rows.map((v) => {
                return {
                    id: v.Id,
                    item: v,
                    name: v.Contact,
                    tel: v.Phone,
                    address: Util.formatLocation(v, true),
                };
            });
        },

        onEdit(address) {
            var dialog = Util.createComponent(
                EditAddress,
                {
                    forward: () => {
                        this.$router.push(`${this.path}/orderAddress`);
                    },
                    back: () => {
                        this.$router.back();
                    },
                    refresh: () => {
                        this.refreshAddressList();
                    },
                },
                document.querySelector("#app")
            );
            dialog.open(address?.id);
        },

        open() {
            this.forward?.();
            this.refreshAddressList();
            this.show = true;
        },

        close() {
            this.back?.();
        },

        ok() {
            let address = this.addressList.find((v) => v.id == this.addressId);
            if (address) {
                this?.submit(address.item);
                this.close();
            } else {
                Toast.fail("请选择地址");
                return;
            }
        },
    },

    created() { },
    mounted() {
        let watchRoute = this.$watch("$route", () => {
            if (!this.$route.path.startsWith(this.path)) {
                this.show = false;
                watchRoute();
            }
        });
    },
};
</script>
<style lang="scss" scoped>
.top {
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
        line-height: 30rem;
        font-size: 17rem;
        margin-left: 10rem;
    }

    ::v-deep {
        .van-button {
            height: 30rem;
            border: 0;
            color: #1989fa;

            .van-button__text {
                margin-left: 5rem;
                font-size: 17rem;
            }
        }
    }
}

::v-deep {
    .van-address-item {
        .van-radio__label {
            min-width: 0;
        }

        .van-address-item__name {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .van-address-item__address {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .van-address-list {
        background-color: #f5f7fa;
        overflow: scroll;

        .van-radio__icon--checked .van-icon {
            background-color: #1989fa;
            border-color: #1989fa;
        }

        .van-address-list__bottom {
            max-width: 750px;
            left: 50%;
            transform: translateX(-50%);
        }

        .van-button--danger {
            color: #fff;
            background-color: #1989fa;
            border: 1px solid #1989fa;
        }

        .van-address-item__name {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .van-address-item__address {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
