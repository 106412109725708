<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="content">
      <van-form ref="form" class="dialog-content" label-width="50rem" @submit="onSubmit">
        <!-- <div v-if="hint" class="hint">
          {{ hint }}
        </div> -->

        <van-field v-model="phone" label="电话" required placeholder="请填写电话"
          :rules="[{ required: true, message: '请填写电话' }]" />
        <van-field v-model="wechat" label="微信号" required placeholder="请填写微信号"
          :rules="[{ required: true, message: '请填写微信号' }]" />
        <div style="margin: 16rem">
          <van-button round block type="danger" color="#009dff" native-type="submit">保存</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import PageTop from "@/components/PageTop";
import Service from "@/utils/service";
import { Toast } from "vant";

export default {
  components: {
    PageTop,
  },
  data() {
    return {
      phone: "",
      wechat: "",
    };
  },
  computed: {},
  watch: {},

  methods: {
    async getContact() {
      let response = await Service.getContact();
      this.phone = response.Phone;
      this.wechat = response.Wechat;
    },

    onSubmit() {
      this.$refs.form
        .validate()
        .then(async () => {
          let data = {
            Phone: this.phone,
            Wechat: this.wechat,
          };
          await Service.editContact(data);
          Toast.success("保存成功");
        })
        .catch(() => { });
    },

    async checkState() {
      let subscribeResult = await Service.checkSubscribe();
      if (subscribeResult.Subscribe) {
        this.getContact();
      } else {
        this.$router.push("/login");
      }
    },
  },
  mounted() {
    this.checkState();
  },
};
</script>

<style lang="scss" scoped>
.hint {
  color: #666;
  font-size: 15rem;
  margin: 20rem 15rem;
}

:deep(.van-cell--required::before) {
  position: relative;
  left: auto;
  right: 3rem;
}

:deep(.van-dialog__footer) {
  display: none;
}
</style>
