<!--  -->
<template>
  <div class="top">
    <img class="logo" src="@/assets/images/logo.png" @click="home" />
    <div class="icon-wrap">
      <slot></slot>
      <van-button to="/login" v-if="!$store.state.isLogin">
        <van-icon class-prefix="iconfont" name="user" />
      </van-button>
      <div id="translateGoogle"></div>
      <van-popover v-model="showPopover" placement="bottom-end" trigger="click" :actions="actions" @select="onSelect">
        <template #reference>
          <van-button>
            <van-icon class-prefix="iconfont" name="list" />
          </van-button>
        </template>
      </van-popover>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showPopover: false,
      actions: [
        { text: "库存管理", url: "/productList" },
        { text: "竞价管理", url: "/biddingList" },
        { text: "订单管理", url: "/orderList" },
        { text: "地址管理", url: "/addressList" },
        { text: "个人信息", url: "/contact" },
        { text: "交易规则", url: "/rule" },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    home() {
      this.$router.push("/");
    },
    onSelect(item) {
      this.$router.push(item.url);
    },
  },
  created() {},
  mounted() {
    let translateParent = document.querySelector("#translateGoogle");
    let translateGoogle = document.querySelector("#google_translate_element");
    if (translateGoogle) {
      this.translateGoogle = translateGoogle;
      translateParent.appendChild(translateGoogle);
    }
  },
  beforeDestroy() {
    if (this.translateGoogle) {
      document.querySelector("body").appendChild(this.translateGoogle);
    }
  },
};
</script>
<style lang="scss" scoped>
.top {
  display: flex;
  justify-content: space-between;

  .logo {
    width: 59rem;
    height: 40rem;
  }

  .icon-wrap {
    display: flex;
    align-items: center;

    .iconfont {
      font-size: 20rem;
      color: #666;
    }

    :deep(.van-dropdown-menu__bar) {
      box-shadow: none;
    }

    :deep(.van-button--default) {
      border: none;
      padding: 0 0;
      margin: 0 0 0 15rem;
    }

    :deep(.van-button) {
      height: auto;
    }

    :deep(.van-popover__wrapper) {
      font-size: 0;
    }
  }
}
</style>
