<template>
  <van-dialog v-if="show" v-model="show" class="full-screen">
    <van-nav-bar :title="id > 0 ? '修改地址' : '新增地址'" left-text="返回" left-arrow @click-left="close()" />

    <van-form ref="form" class="dialog-content" label-width="60rem" @submit="onSubmit">
      <van-field v-model="contact" label="联系人" placeholder="请填写联系人" />
      <van-field v-model="phone" label="电话" placeholder="请填写电话" />

      <van-field v-model="countryDesc" is-link readonly label="国家" placeholder="请选择国家" @click="countrySheetShow = true" />
      <van-action-sheet v-model="countrySheetShow" :actions="countryList" close-on-click-action @select="
        country = $event.value;
      countryDesc = $event.name;
                " />

      <van-field v-model="province" label="省/州" placeholder="请填写省/州" />
      <van-field v-model="city" label="城市" placeholder="请填写城市" />
      <van-field v-model="zipCode" label="邮编" placeholder="请填写邮编" />

      <van-field v-model="address1" label="地址1" placeholder="请填写地址1" />
      <van-field v-model="address2" label="地址2" placeholder="请填写地址2" />

      <div style="margin: 16rem">
        <van-button round block type="danger" color="#009dff" native-type="submit">保存</van-button>
      </div>
    </van-form>
  </van-dialog>
</template>

<script>
import Service from "@/utils/service";
import { Toast } from "vant";

export default {
  components: {},
  props: ["forward", "back", "refresh"],
  data() {
    return {
      show: false,
      countrySheetShow: false,

      id: 0,
      contact: "",
      phone: "",

      country: "",
      countryDesc: "",
      countryList: [
        ...this.$store.state.countryList.map((v) => {
          return { name: v.Name, value: v.Id };
        }),
      ],

      province: "",
      city: "",
      zipCode: "",

      address1: "",
      address2: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    open(id) {
      this.forward?.call();
      this.show = true;
      if (id) {
        this.load(id);
      }
    },

    close() {
      this.show = false;
      this.back?.call();
    },

    async load(id) {
      this.id = id;
      let result = await Service.getAddressDetail({
        id: id,
      });

      this.contact = result.Contact;
      this.country = result.Country;
      this.phone = result.Phone;
      this.countryDesc = result.CountryDesc;
      this.province = result.Province;
      this.city = result.City;
      this.zipCode = result.ZipCode;
      this.address1 = result.Address1;
      this.address2 = result.Address2;
    },

    async onSubmit() {
      let data = {
        Id: this.id,
        Contact: this.contact,
        Phone: this.phone,

        Country: this.country,
        Province: this.province,
        City: this.city,
        ZipCode: this.zipCode,

        Address1: this.address1,
        Address2: this.address2,
      };

      await Service.editAddress(data);

      Toast.success("保存成功");
      this.close();
      this.refresh?.();
    },

    mounted() { },
  },
};
</script>

<style lang="scss" scoped>
.img {
  display: block;
  width: 120rem;
  height: 120rem;
  margin: 10rem auto;
}

.currency {
  color: #ea3a3c;
}

form {
  margin: 0 26rem;
}
</style>
