<template>
  <div class="page">
    <div class="header">
      <page-top></page-top>
    </div>
    <div class="content">
      <div v-if="isWechat">
        <div class="logo-wrapper">
          <van-image :src="require('@/assets/images/logo.png')"></van-image>
        </div>
        <div class="btn-wrapper">
          <van-button round block type="danger" color="#009dff" url="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg4MjgxOTA1NQ==&scene=110#wechat_redirect">
            点击微信授权登录</van-button
          >
        </div>
      </div>
      <div v-else>
        <van-image :src="require('@/assets/images/gzh.png')"></van-image>
      </div>
    </div>
  </div>
</template>
<script>
import PageTop from "@/components/PageTop";
import Util from "@/utils/util";
import Service from "@/utils/service";

export default {
  components: {
    PageTop,
  },
  data() {
    return {
      isWechat: Util.isWechat(),
    };
  },
  computed: {},
  watch: {},

  methods: {},

  mounted() {},
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  padding: 20rem 0;
}

.btn-wrapper {
  margin: 16rem;
}
</style>
