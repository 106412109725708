export default {
  isLogin: false,

  "categoryList": [
    { "Id": 1, "Name": "电子-Electronics" },
    { "Id": 2, "Name": "电脑周边-Computers" },
    { "Id": 3, "Name": "智慧家庭设备-Smart Home" },
    { "Id": 4, "Name": "家居花园工具-Home Garden&Tools" },
    { "Id": 5, "Name": "宠物用品-Pet Supplies" },
    { "Id": 6, "Name": "食品-Food&Grocery" },
    { "Id": 7, "Name": "美妆健康-Beauty&Health" },
    { "Id": 8, "Name": "玩具-Toys Kids & Baby" },
    { "Id": 9, "Name": "手工品-Handmade" },
    { "Id": 10, "Name": "运动品-Sports" },
    { "Id": 11, "Name": "户外-Outdoors" },
    { "Id": 12, "Name": "汽车&工业用品-Automotive&Industrial" },
  ],

  "countryList": [
    { "Id": 1, "Name": "美国", "EnglishName": "USA", "currency": 2 },
    { "Id": 2, "Name": "英国", "EnglishName": "GBR", "currency": 6 },
    { "Id": 3, "Name": "日本", "EnglishName": "JPN", "currency": 4 },
    { "Id": 4, "Name": "德国", "EnglishName": "GER", "currency": 3 },
    { "Id": 5, "Name": "法国", "EnglishName": "FRA", "currency": 3 },
    { "Id": 6, "Name": "欧盟", "EnglishName": "EU", "currency": 3 },
    { "Id": 7, "Name": "加拿大", "EnglishName": "CAN", "currency": 5 },
  ],

  "currencyList": [
    { "Id": 1, "Name": "人民币" },
    { "Id": 2, "Name": "美元" },
    { "Id": 3, "Name": "欧元" },
    { "Id": 4, "Name": "日元" },
    { "Id": 5, "Name": "加元" },
    { "Id": 6, "Name": "英镑" },
  ],

}
